// CanvasAnimation.js
import React, { useRef, useEffect } from 'react';

const CanvasAnimation = () => {
    const canvasRef = useRef(null);
    let t = 0;

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const w = canvas.width = window.innerWidth;
        const h = canvas.height = window.innerHeight;

        // Set the canvas background color to white
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, w, h);

        const txt = () => {
            const _t = "".split("").join(String.fromCharCode(0x2004));
            ctx.font = "4em Poiret One";
            ctx.fillStyle = 'hsla(277, 90%, 50%, .8)';
            ctx.fillText(_t, (canvas.width - ctx.measureText(_t).width) * 0.5, canvas.height * 0.5);
            return _t;
        }

        const draw = (a, b, t) => {
            ctx.lineWidth = 0.8;
            ctx.fillStyle = '#fff';  // Ensure the background stays white
            ctx.fillRect(0, 0, w, h);

            for (let i = -60; i < 60; i += 1) {
                ctx.strokeStyle = 'hsla(240, 100%, 50%, .8)'; // Blue color for the animation
                ctx.beginPath();
                ctx.moveTo(0, h / 2);
                for (let j = 0; j < w; j += 10) {
                    ctx.lineTo(10 * Math.sin(i / 10) + j + 0.008 * j * j,
                        Math.floor(h / 2 + (j / 1.5 * Math.sin(j / 50 - t / 100 - i / 118) * 1) + (i * 0.9) * Math.sin(j / 25 - (i + t) / 65)));
                }
                ctx.stroke();
            }
        }

        const resizeHandler = () => {
            canvas.width = w;
            canvas.height = h;
            ctx.fillStyle = '#fff'; // White background
            ctx.fillRect(0, 0, w, h);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();

        const run = () => {
            window.requestAnimationFrame(run);
            t += 1.5; // Slow down the animation
            draw(33, 52 * Math.sin(t / 2400), t);
            txt();
        };

        run();

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <canvas ref={canvasRef} style={{ display: 'block', width: '100%', height: '100%' }} className='canvas'></canvas>
    );
};

export default CanvasAnimation;
