import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import HomeSearch from '../HomeSearch/HomeSearch';
import { useState } from 'react';
import './HomeBanner.css'
import ReactPlayer from 'react-player';

const HomeBanner = () => {
    const [isHomeSearchVisible, setIsHomeSearchVisible] = useState(false);

    const handleLinkClick = () => {
        setIsHomeSearchVisible(true);
    };
    return (
        <div className="d-margin">
            <Container>
                <div className='HomebannerMain'>
                    <div className='HomebannerImg'>
                        {/* <Image src='/assets/home-banner.png' /> */}
                        <ReactPlayer
          width='100%'
          height='100%'
          playing={true}
          muted={true}
          loop={true}
          controls={false}
          url="/assets/Animation-02.mp4"
        />
                    </div>
                    <div className={`HomebannerInfo ${isHomeSearchVisible ? 'showSearch' : ''}`}>
                        <h1 data-aos="fade-up">The way humans create and share personified AI</h1>
                        <Link to="javascript:void(0)" onClick={handleLinkClick} className='homelogo-icon'><span><Image src='/assets/logo-icon.svg' /></span> <small>Click Me</small></Link>
                        <HomeSearch />
                        <Link to="https://orbofi.com/" className='btn-blue btn-blue-icon '>Create your AI <i><Icon icon="mdi:sparkles" /></i></Link>
                    </div>
                </div>
            </Container>
        </div>
    )
};

export default HomeBanner;
