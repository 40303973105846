import React, { useEffect, useState } from 'react';
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import DictateButton from 'react-dictate-button';
import { Icon } from "@iconify/react";
const DictationComponent = ({ subscriptionKey, region, setMessage, streamTextGeneration, updateMessageToSend, isListening, setIsListening, setIsVoiceToVoice, userHangUp }:any) => {
  const [speechServices, setSpeechServices] = useState(null);

  useEffect(() => {
    const initializeSpeechServices = async () => {
      const services = await createPonyfill({
        credentials: {
          region: "germanywestcentral",
          subscriptionKey: "9a3cfaf40ad04b11a1f4ee74d56d4a25",
        },
      });
      setSpeechServices(services);
    };

    initializeSpeechServices();

  }, [subscriptionKey, region]);

  if (!speechServices) {
    return <div>Loading...</div>;
  }



  const handleDictate = ({ result } : any) => {
    if (isListening == true) {
      try {
        updateMessageToSend(result.transcript);
        setIsVoiceToVoice(true);
        setMessage(result.transcript);
        streamTextGeneration(false);
      } catch (error) {
        console.error("Error in handleDictate:", error);
      }
    }

  };

  const handleProgress = ({ results } : any) => {
    try {
      console.log(userHangUp);
      if (userHangUp == true) {
        setIsListening(false);
        return;
      }
      console.log("handleProgress");
      setIsListening(true);
      console.log(results);
      const interimTranscript = results.map((result : any) => result.transcript).join(' ');
      setMessage(interimTranscript);
    } catch (error) {
      console.error("Error in handleProgress:", error);
    }
  };

  const { SpeechGrammarList, SpeechRecognition } = speechServices;

  return (
    <DictateButton
      onDictate={handleDictate}
      speechGrammarList={SpeechGrammarList}
      speechRecognition={SpeechRecognition}
      onProgress={handleProgress}
      className="dictate-button"
    >
      <Icon icon="ph:microphone" style={{ color: isListening ? "red" : "grey" }} />

    </DictateButton>
  );
};

export default DictationComponent;
