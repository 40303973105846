import { Col, Container, Image, Row } from 'react-bootstrap';
import './AppUseCase.css'

const AppUseCase = () => {

    return (
        <section className='AppUseCase-Main' id='UseCases'>
            <Container>
                <div className='bg-blue py-100'>
                    <h2 className='main-title text-white text-center '>Our App U​se Cases</h2>
                    <Row>
                        <Col lg={4} md={4}>
                            <div className='AppUseCase-item' data-aos="fade-up">
                                <div className='AppUseCase-img'>
                                    <Image src='/assets/phones.png' />
                                </div>
                                <div className='AppUseCase-info'>
                                    <h6>For anyone with a ​phone from around ​the world</h6>
                                    <p>Create  your own Jarvis-like AI companion, ​voice-powered, with custom personality, ​character, and skills,  powerful AI, but most ​importantly, with voices you want to ​preserve forever</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className='AppUseCase-item' data-aos="fade-up">
                                <div className='AppUseCase-img'>
                                    <Image src='/assets/creators.png' />
                                </div>
                                <div className='AppUseCase-info'>
                                    <h6>For Creators</h6>
                                    <p>Create your personal voice-powered AI ​assistants based on your own personality ​and your own voice,  that your followers ​can​ use as their own companions</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className='AppUseCase-item' data-aos="fade-up">
                                <div className='AppUseCase-img'>
                                    <Image src='/assets/developers.png' />
                                </div>
                                <div className='AppUseCase-info'>
                                    <h6>For Developers & ​Enterprise</h6>
                                    <p>Create  branded, voice, AI companions, ​trained on your own Data, that serve your ​customers wherever are, and integrated ​straight into your website,  application, e-​com​merce site, or IRL stores.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
};

export default AppUseCase;
