import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { toast } from "react-hot-toast";
import Context from './Context';
import usePrevious from './usePrevious';
import useRefFrom from './useRefFrom';
import vendorPrefix from './vendorPrefix';

function applyAll(...fns) {
  return function () {
    fns.forEach(fn => fn.apply(this, arguments));
  };
}

function recognitionAbortable(recognition) {
  return !!(recognition && typeof recognition.abort === 'function');
}

const Composer = forwardRef(({
  children,
  extra,
  grammar,
  lang,
  onDictate,
  onError,
  onProgress,
  onRawEvent,
  speechGrammarList,
  speechRecognition,
  started
}, ref) => {
  const [readyState, setReadyState] = useState(0);
  const emitDictateOnEndRef = useRef(false);
  const extraRef = useRefFrom(extra);
  const grammarRef = useRefFrom(grammar);
  const langRef = useRefFrom(lang);
  const notAllowedRef = useRef(false);
  const onDictateRef = useRefFrom(onDictate);
  const onErrorRef = useRefFrom(onError);
  const onProgressRef = useRefFrom(onProgress);
  const onRawEventRef = useRefFrom(onRawEvent);
  const prevSpeechRecognition = usePrevious(speechRecognition);
  const recognitionRef = useRef();
  const speechGrammarListRef = useRefFrom(speechGrammarList);
  const speechRecognitionRef = useRefFrom(speechRecognition);

  if (prevSpeechRecognition !== speechRecognition) {
    notAllowedRef.current = false;
  }

  const handleAudioEnd = useCallback(
    ({ target }) => target === recognitionRef.current && setReadyState(2),
    [recognitionRef, setReadyState]
  );

  const handleAudioStart = useCallback(
    (event = {}) => {
      const { target } = event;
      if (target && target !== recognitionRef.current) {
        return;
      }
      setReadyState(2);
      emitDictateOnEndRef.current = true;
      onProgressRef.current && onProgressRef.current({ abortable: recognitionAbortable(target), type: 'progress' });
    },
    [emitDictateOnEndRef, onProgressRef, recognitionRef, setReadyState]
  );

  const handleEnd = useCallback(
    ({ target }) => {
      if (target !== recognitionRef.current) {
        return;
      }


      if (emitDictateOnEndRef.current) {
        onDictateRef.current && onDictateRef.current({ type: 'dictate' });
      }

      // Restart recognition after it ends
      // recognitionRef.current.start();
      // return;
    },
    [emitDictateOnEndRef, onDictateRef, recognitionRef]
  );

  const handleError = useCallback(
    event => {
      if (event.target !== recognitionRef.current) {
        return;
      }

      console.log("Error");
      // toast.error()

      emitDictateOnEndRef.current = false;
      recognitionRef.current = undefined;

      if (event.error === 'not-allowed') {
        notAllowedRef.current = true;
      }

      setReadyState(0);

      onErrorRef.current && onErrorRef.current(event);
    },
    [emitDictateOnEndRef, onErrorRef, notAllowedRef, recognitionRef, setReadyState]
  );

  const handleRawEvent = useCallback(
    event => {
      if (event.target !== recognitionRef.current) {
        return;
      }
      // return;
      onRawEventRef.current && onRawEventRef.current(event);
    },
    [onRawEventRef, recognitionRef]
  );

  const handleResult = useCallback(
    ({ results: rawResults, target }) => {
      if (target !== recognitionRef.current) {
        return;
      }

      if (rawResults.length) {
        const results = [].map.call(rawResults, alts => {
          const firstAlt = alts[0];

          return {
            confidence: firstAlt.confidence,
            transcript: firstAlt.transcript
          };
        });

        const first = rawResults[0];

        console.log(rawResults[0]);

        if (first.isFinal) {
          
          // recognitionRef.current = undefined;
          // recognitionRef.current.start();
          // recognitionRef.current.reset();
          // onProgressRef.current.resetTranscript();
          // onProgressRef.current({ abortable: recognitionAbortable(target), empty, type: 'progress' });
          
          // onDictateRef.current;

          onDictateRef.current && onDictateRef.current({ result: results[0], type: 'dictate' });
          startRecognition();
          
          // onProgressRef.current({ abortable: recognitionAbortable(target), results, type: 'progress' });
        } else {
          console.log(results);
          console.log(onProgressRef);
          onProgressRef.current &&
            onProgressRef.current({ abortable: recognitionAbortable(target), results, type: 'progress' });
        }
      }
    },
    [onDictateRef, onProgressRef, recognitionRef]
  );

  const handleStart = useCallback(
    ({ target }) => target === recognitionRef.current && setReadyState(1),
    [recognitionRef, setReadyState]
  );

  const startRecognition = useCallback(() => {
    if (!speechRecognitionRef.current || notAllowedRef.current) {
      throw new Error('Speech recognition is not supported');
    }


    const grammars = speechGrammarListRef.current && grammarRef.current && new speechGrammarListRef.current();
    const recognition = (recognitionRef.current = new speechRecognitionRef.current());

    if (grammars) {
      grammars.addFromString(grammarRef.current, 1);
      recognition.grammars = grammars;
    }

    recognition.lang = langRef.current;
    recognition.interimResults = true;
    recognition.continuous = true;
    recognition.onaudioend = applyAll(handleAudioEnd, handleRawEvent);
    recognition.onaudiostart = applyAll(handleAudioStart, handleRawEvent);
    recognition.onend = applyAll(handleEnd, handleRawEvent);
    recognition.onerror = applyAll(handleError, handleRawEvent);
    recognition.onnomatch = handleRawEvent;
    recognition.onresult = applyAll(handleResult, handleRawEvent);
    recognition.onsoundend = handleRawEvent;
    recognition.onsoundstart = handleRawEvent;
    recognition.onspeechend = handleRawEvent;
    recognition.onspeechstart = handleRawEvent;
    recognition.onstart = applyAll(handleStart, handleRawEvent);


    const { current: extra } = extraRef;

    extra &&
      Object.entries(extra).forEach(([key, value]) => {
        if (key !== 'constructor' && key !== 'prototype' && key !== '__proto__') {
          recognition[key] = value;
        }
      });

    recognition.start();
  }, [
    extraRef,
    grammarRef,
    handleAudioEnd,
    handleAudioStart,
    handleEnd,
    handleError,
    handleRawEvent,
    handleResult,
    handleStart,
    langRef,
    notAllowedRef,
    recognitionRef,
    speechGrammarListRef,
    speechRecognitionRef
  ]);

  useEffect(() => {
    if (started) {
      startRecognition();
    }

    return () => {
      const { current: recognition } = recognitionRef;

      if (recognition) {
        if (recognitionAbortable(recognition)) {
          recognition.abort();
        } else {
          throw new Error('Failed to stop recognition while the current one is ongoing and is not abortable.');
        }
      }
    };
  }, [started, startRecognition]);

  const abortable = recognitionAbortable(recognitionRef.current) && readyState === 2;
  const supported = !!speechRecognition && !notAllowedRef.current;

  const context = useMemo(
    () => ({
      abortable,
      readyState,
      supported
    }),
    [abortable, readyState, supported]
  );

  useImperativeHandle(ref, () => ({
    handleAudioStart,
    startRecognition
  }), [handleAudioStart, startRecognition]);

  return (
    <Context.Provider value={context}>
      <Context.Consumer>{context => (typeof children === 'function' ? children(context) : children)}</Context.Consumer>
    </Context.Provider>
  );
});

Composer.defaultProps = {
  children: undefined,
  extra: undefined,
  grammar: undefined,
  lang: undefined,
  onDictate: undefined,
  onError: undefined,
  onProgress: undefined,
  onRawEvent: undefined,
  speechGrammarList: navigator.mediaDevices && navigator.mediaDevices.getUserMedia && vendorPrefix('SpeechGrammarList'),
  speechRecognition: navigator.mediaDevices && navigator.mediaDevices.getUserMedia && vendorPrefix('SpeechRecognition'),
  started: undefined
};

Composer.propTypes = {
  children: PropTypes.any,
  extra: PropTypes.any,
  grammar: PropTypes.string,
  lang: PropTypes.string,
  onDictate: PropTypes.func,
  onError: PropTypes.func,
  onProgress: PropTypes.func,
  onRawEvent: PropTypes.func,
  speechGrammarList: PropTypes.any,
  speechRecognition: PropTypes.any,
  started: PropTypes.any
};

export default Composer;
