import { Col, Container, Image, Row } from 'react-bootstrap';
import './TeamSupports.css'

const TeamSupports = () => {

    return (
        <section className='TeamSupports-Main py-100'>
            <h2 className='main-title' data-aos="fade-up">Team & Supporters From</h2>
            <Container>
                {/* <span><Image src="/assets/line.svg" /></span> */}
                <ul className='TeamSupports-logos' data-aos="fade-up">
                    <li><Image src='/assets/TeamSupports-img-01.svg' /></li>
                    <li><Image src='/assets/TeamSupports-img-02.svg' /></li>
                    <li><Image src='/assets/TeamSupports-img-03.svg' /></li>
                    <li><Image src='/assets/TeamSupports-img-04.svg' /></li>
                </ul>
                {/* <span><Image src="/assets/line.svg" /></span> */}
            </Container>
        </section>
    )
};

export default TeamSupports;
