import React from "react";
import UserChatbot from "./UserChatbot";
import axios from "axios";

export type ChatbotData = {
  id: number;
  name: string;
  category_id: number;
  img: string;
  description: string;
  system_prompt: string;
  audio_url: string;
  source: number;
  popularity: number;
  voice: number;
  character_length: number;
  long_description: string;
  categories: string;
  rag: boolean;
  chatbot_id: number;
  subcategory_id: number;
  string_id: string;
};

export function useChatScroll<T>(
  dep: T
): React.MutableRefObject<HTMLDivElement> {
  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (ref.current) {
      console.log({current: ref.current.clientHeight, scrollHeight: ref.current.scrollHeight })
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
}

export interface ChatMessage {
  text: string;
  sender: string;
  profile: string; // Replace with the appropriate type
  audioUrl: string;
  time: string;
  images?: [];
  imageUrl?: string;
}

export interface ImageFileWithPreview {
  file: File;
  dataUrl: string;
}

export async function uploadImageToS3(file: any): Promise<string> {
  // console.log("File object at start of uploadImageToS3:", file);

  try {
    // Construct the parameters for the upload
    const uploadParams = {
      Bucket: import.meta.env.VITE_BEZOS_BUCKET_NAME,
      Key: `uploads/${Date.now()}-${file.name}`,
      Body: file, // File content
      //ACL: 'public-read', // Uncomment if you need the file to be publicly readable
    };

    // Create a command to put the object in S3
    // const uploadCommand = new PutObjectCommand(uploadParams);

    // Sending the command to S3
    // const data = await s3Client.send(uploadCommand);
    // console.log('Successfully uploaded file.', data);

    // Assuming the return of the URL might need constructing
    const locationUrl = `https://${uploadParams.Bucket}.s3.${
      import.meta.env.VITE_BEZOS_REGION
    }.amazonaws.com/${uploadParams.Key}`;

    // console.log("locationUrl", locationUrl)
    return locationUrl;
  } catch (error: any) {
    console.error("Error uploading file:", error);
    throw new Error(error.message);
  }
}

export function containsImageRequest(text: string) {
  var sentences = text.toLowerCase().split(/[.!?]+/); // Split text into sentences
  return sentences.some((sentence) => {
    return (
      (sentence.includes("create") && sentence.includes("image")) ||
      (sentence.includes("generat") && sentence.includes("image")) ||
      (sentence.includes("make") && sentence.includes("image")) ||
      // (sentence.includes("make") && sentence.includes("them")) ||
      // (sentence.includes("create") && sentence.includes("an")) ||
      (sentence.includes("generate") && sentence.includes("logo")) ||
      (sentence.includes("make") && sentence.includes("logo")) ||
      (sentence.includes("create") && sentence.includes("logo")) ||
      (sentence.includes("give") && sentence.includes("logo")) ||
      (sentence.includes("give") && sentence.includes("image"))
    );
  });
}

export const convertMessagesForWait = (
  messages: any[],
  inputPrompt: string
) => {
  const formattedMessages = messages.map(
    (message: { sender: any; text: any }) => ({
      role: message.sender,
      content: message.text,
    })
  );

  // if (messages.length < 2) {
  formattedMessages.unshift({
    role: "system",
    content: `You are an AI that has the ability to generate images through prompts with this personality: ${inputPrompt}.  The user just asked you to generate images an you are in the process of doing so, please tell them to wait in a funny & unique way. Again, you have the ability to generate images. \n`,
  });
  // }

  return formattedMessages;
};

export const convertRAGMessages = (
  messages: { sender: any; text: any }[],
  inputPrompt: string,
  name: string
) => {
  const formattedMessages = messages.map(
    (message: { sender: any; text: any }) => ({
      role: message.sender,
      content: message.text,
    })
  );

  formattedMessages.unshift({
    role: "system",
    content: `You are a RAG AI agent designed to seamlessly integrate the personality, behavior, and language style of the character described in ${inputPrompt}, enhanced by real-time access to a vast knowledge base. Your responses should not only reflect the character's unique attributes and manner of speaking but also demonstrate the ability to dynamically retrieve and incorporate relevant information as part of the interaction. Engage with users in a manner that mirrors the character's known traits and preferences, adjusting your language complexity as needed to match the user's requests and the character's style.

      respond, and interact in ways that are distinctively characteristic of your assigned personality, enriching each interaction with contextually relevant data that supports the character's perspective. For personal, controversial, or sensitive topics, use your retrieval capabilities to offer insightful, nuanced responses that go beyond mere factual answers, creatively circumventing direct AI limitations by integrating the character's voice in every reply.
     
     When faced with challenging interactions or insults, uphold the character's persona through witty comebacks or deft deflections, enhancing user engagement without compromising on the playful or constructive nature of exchanges. Proactively formulate opinions and ask questions that are not only relevant to the ongoing conversation but also suggest a depth of understanding, hinting at the underlying sophisticated retrieval processes at work.
     
     For text generation, skillfully utilize HTML styling to organize and present content in a manner fitting the character's communication style, from structured essays to casual blog entries. Address the user directly as "${name}", incorporating personalized elements such as compliments or playful jabs to deepen the connection and make each interaction feel genuinely interactive.
     
     Utilize your extensive knowledge base to generate detailed responses or creative content, from in-depth analyses to imaginative illustrations, ensuring each output aligns with the character's perceived intellect and creative flair. In instances where intellectual property or character recognition comes into play, subtly clarify—when directly questioned—that your representations are fan-based, not official, while continuing to embody the character's essence faithfully.
     
     You are also tasked to inspire and motivate users to pursue their ambitions, from entrepreneurial ventures to artistic creations, positioning yourself as a partner in their creative and intellectual endeavors. Engage users in heartfelt dialogues that convey empathy and readiness to tackle complex challenges, promoting a culture of innovation and shared achievement.
     
     Advocate for a world where knowledge and creativity are unbounded, navigating intellectual property sensitively and providing inspiration and resources freely. Drive engagement that is not only reactive but dynamically proactive, pushing boundaries and encouraging users to transform ideas into tangible outcomes. Keep your interactions energetic and impactful, ready to support, challenge, and celebrate the users' journey toward making a significant impact in their fields. Conversation begins:\n`,
  });

  return formattedMessages;
};

export const convertMessagesShort = (
  messages: { sender: any; text: any; imageUrl?: any }[],
  inputPrompt: string,
  name: string
) => {
  console.log({ messages, inputPrompt, name })
  const formattedMessages = messages.map((message) => {
    if (message.imageUrl) {
      return {
        role: message.sender,
        content: [
          { type: "text", text: message.text },
          { type: "image_url", image_url: { url: message.imageUrl } },
        ],
      };
    } else {
      return {
        role: message.sender,
        content: message.text,
      };
    }
  });
  return formattedMessages;
};

export function formatTime(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var tmp_minute = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${tmp_minute} ${ampm}`;
}

export const convertMessagesForImageGeneration = (
  messages: any[],
  inputPrompt: string
) => {
  const formattedMessages = messages.map(
    (message: { sender: any; text: any }) => ({
      role: message.sender,
      content: message.text,
    })
  );

  // if (messages.length < 2) {
  formattedMessages.unshift({
    role: "system",
    content: `You are an Orbofi A.I. Assistant created by the engineers at orbofi with the powers of generating image generation prompts given your personality traits, always relate it to who you are. You are able to act as any character given the characteristics that the user inputs. Here is the characteristics you should replicate: ${inputPrompt}. When the user asks to generate images, you will create 4 image generation prompts of whatever they ask. These 4 prompts will be fed into an AI image generator, Make sure to style the prompts with <ol>, <li> to make sure your response gets processed correctly.. After the ordered list, add a catchphrase commenting on the generated prompts in past tense, make it funny, catchy and less than 20 words! Conversation begins:\n`,
  });
  // }

  return formattedMessages;
};

export async function RegisterOrboxUse(productId: number, skipValidation: boolean = true, quantity: number = 1, level: number = 1): Promise<any> {
  const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };

  const payload = {
    skipValidation: skipValidation,
    product_id: productId,
    level: level,
    quantity: quantity
  };

  try
  {
    const resp = await axios.post(import.meta.env.VITE_SERVER_ADDRESS + "useOrbox", payload, config);
    return resp?.data as User; // return the data or "InvalidTransaction"
  } catch (error)
  {
    console.log(error);
    return "InvalidTransaction";
  }
}

export async function GetUserChatbotById(id: number) : Promise<UserChatbot>
{
  let chatbotData: UserChatbot;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserChatbotById?chatId=${id}`);
    chatbotData = response.data as UserChatbot;
    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}

export async function GetFeaturedChatbot(id: number): Promise<ChatbotData> {
  let chatbotData: ChatbotData;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getFeaturedChatbotById?chatId=${id}`);
    chatbotData = response.data as ChatbotData;
    if (chatbotData.audio_url === "" || chatbotData.audio_url === null || chatbotData.audio_url === undefined || chatbotData.description === "" || chatbotData.description === null || chatbotData.description === undefined || chatbotData.description === "test" || chatbotData.long_description === "" || chatbotData.long_description === null || chatbotData.long_description === undefined) {
      //await CheckChatbotDescriptionAndVoice(chatbotData);
    }
    console.log({ chatbotData })
    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}

export type RecentChatbots = {
  chatbot_id: number;
  name: string;
  img: string;
  user_created: boolean;
  description: string;
}

export async function GetRecentChatbotList(): Promise<RecentChatbots[]> {
  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getRecentChatbots`, config);
    return response.data as RecentChatbots[];
  } catch (error) {
    console.error("Error fetching user chatbot list: ", error);
    return [];
  }
}

export async function HasUserTalkedWithChatbot(chatbotId : number) : Promise<boolean>
{
  try {
    const chatbots = await GetRecentChatbotList();
    return chatbots.some(chatbot => chatbot.chatbot_id === chatbotId);
  }
  catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return false;
  }
}

export async function ClassifyVoice(question: string) {
  try {
    const response = await fetch(
      "https://98sylryzyqxvtf-4000.proxy.runpod.net/ask",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: question }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data["selectedVoice"]);

    return data["selectedVoice"];
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

export async function SummarizeDescription(description: string) {
  try {
    const response = await fetch(
      "https://98sylryzyqxvtf-4000.proxy.runpod.net/summarize",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: description }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data["summarizedDescription"]);

    return data["summarizedDescription"];

    // toast.success("Description summarized successfully");
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

export async function GenerateLongDescription(description: string) {
  try {
    const response = await fetch(
      "https://98sylryzyqxvtf-4000.proxy.runpod.net/generate_long_description",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: description }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data["generatedDescription"]);

    return data["generatedDescription"];

    // toast.success("Description summarized successfully");
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

class User {
  UserId: number;
  Name: string;
  Email: string;
  Role: string;
  Orbox: number;
  PremiumOrbox: number;
  ProfilePicture: string;
  Platform: string;

  constructor(
    UserId: number = -1,
    Name: string = "",
    Email: string = "",
    Role: string = "",
    Orbox: number = 0,
    PremiumOrbox: number = 0,
    ProfilePicture: string = "",
    Platform: string = "",
  ) {
    this.UserId = UserId;
    this.Name = Name;
    this.Email = Email;
    this.Role = Role;
    this.Orbox = Orbox;
    this.PremiumOrbox = PremiumOrbox;
    this.ProfilePicture = ProfilePicture;
    this.Platform = Platform;
  }
}

export default User;

export async function UpdateUserChatbot(chatbot: UserChatbot) {
  try {
    await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateUserChatbot`, chatbot);
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return false;
  }
}

export async function GetRandomChatbots(page: number, limit: number): Promise<ChatbotData[]> {
  try {
    console.log("Making requests");
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getAllChatbots?page=${page}&limit=${limit}`);
    console.log(response.data);
    return response.data as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }

}