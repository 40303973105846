import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import './AIClonesbots.css'
import { ChatbotData, GetRandomChatbots } from '../Chatpopup/ChatbotUtils';
import { useEffect, useState } from 'react';
const images = [
    "https://picsum.photos/200/300?image=1050",
    //...
    "https://picsum.photos/300/300?image=206",
]
const AIClonesbots = () => {
    const [chatbots, setChatbots] = useState<ChatbotData[]>([]);

    useEffect(() => {
        fetchChatbots(0);
    }, [0])

    function fetchChatbots(page: number) {
        const itemsToFetch = 28
        GetRandomChatbots(page, itemsToFetch).then((newChatbots) => {
            console.log("newChatbots", newChatbots);
            const uniqueNewChatbots = newChatbots.filter(
                newChatbot => !chatbots.find(chatbot => chatbot.id === newChatbot.id)
            );
            const chatbotsList = chatbots.concat(uniqueNewChatbots);
            setChatbots(chatbotsList);
            //   setRecords(prevRecords => prevRecords + newChatbots.length);
            //   setHasMore(newChatbots.length === itemsToFetch);
        });
    }

    console.log({ chatbots })

    return (
        <section className='AIClonesbots-Main py-100' id='AIClones'>
            <Container>
                <h2 data-aos="fade-up" className='main-title text-center'>Explore the world’s largest ​platform of AI clones</h2>
                <p data-aos="fade-up" className='main-title-discription'>Discover more then 2.5 million different AI companion, and AI clone of anyone you would think of. </p>
                <div className='text-center mb-4'>
                    <Link to="https://www.orbofi.com/explore" className='btn-blue btn-blue-icon '>Explore <i><Icon icon="mdi:sparkles" /></i></Link>
                </div>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 7 }}
                >
                    <Masonry className="AIClonesbotsWrap">
                        {chatbots.map((item, i) => (
                            <Link data-aos="fade-up" to={`https://www.orbofi.com//chat/${item.string_id}`} className='AIClonesbots-item-main' target='_blank'>
                                <div className='AIClonesbots-img'>
                                    <Image src={item.img} key={i} />
                                </div>
                                <div className='AIClonesbots-info'>
                                    <h6>{item.name}</h6>
                                    <p>{item.description} </p>
                                </div>
                            </Link>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
                {/* <div className='text-center mt-lg-5 mt-md-4 mt-3'>
                    <Link data-aos="fade-up" to="#" className='btn-blue btn-blue-icon'>Create your AI <i><Icon icon="mdi:sparkles" /></i></Link>
                </div> */}
            </Container>
        </section>
    )
};

export default AIClonesbots;
