import { useEffect, useRef } from "react";
import ChatbotBoxIframe from "./ChatbotBoxIframe";

const ChatbotBox = () => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentDocument) {
            iframe.contentDocument.open();
            iframe.contentDocument.write(JSON.stringify(<ChatbotBoxIframe />));
            iframe.contentDocument.close();
        }
    }, []);
    return (
        <>
            <ChatbotBoxIframe /> 
            {/* <iframe
                ref={iframeRef}
                style={{
                    width: '100%',
                    height: '668px',
                    border: 'none',
                }}
                title="Chat Page"
            ></iframe> */}
        </>
    )
}

export default ChatbotBox;