import React, { useState } from 'react';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { publicApiType } from 'react-horizontal-scrolling-menu';
import { Icon } from '@iconify/react';
import { Steps } from "intro.js-react";
import LordIcon from '../LordIcon/LordIcon';

class DragManager {
    clicked: boolean;
    dragging: boolean;
    position: number;

    constructor() {
        this.clicked = false;
        this.dragging = false;
        this.position = 0;
    }

    public dragStart = (ev: React.MouseEvent) => {
        this.position = ev.clientX;
        this.clicked = true;
    };

    public dragStop = () => {
        window.requestAnimationFrame(() => {
            this.dragging = false;
            this.clicked = false;
        });
    };

    public dragMove = (ev: React.MouseEvent, cb: (posDiff: number) => void) => {
        const newDiff = this.position - ev.clientX;

        const movedEnough = Math.abs(newDiff) > 5;

        if (this.clicked && movedEnough) {
            this.dragging = true;
        }

        if (this.dragging && movedEnough) {
            this.position = ev.clientX;
            cb(newDiff);
        }
    };
}

function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {

    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else {
        apiObj.scrollPrev();
    }
}
function Arrow({
    children,
    disabled,
    onClick,
    className,
    testId,
}: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
    className?: string;
    testId: string;
}) {
    return (
        <button type='button'
            disabled={disabled}
            onClick={onClick}
            className={"arrow" + `-${className}`}
            data-testid={testId}
        >
            {children}
        </button>
    );
}

function LeftArrow() {
    const { initComplete, isFirstItemVisible, scrollPrev } =
        React.useContext<publicApiType>(VisibilityContext);

        console.log({ initComplete, isFirstItemVisible, scrollPrev })

    return (
        <Arrow
            disabled={!initComplete || (initComplete && isFirstItemVisible)}
            onClick={() => scrollPrev()}
            testId="left-arrow"
        >
            <Icon icon="iconamoon:arrow-left-2-bold" />
        </Arrow>
    );
}

function RightArrow() {
    const { initComplete, isLastItemVisible, scrollNext } =
        React.useContext<publicApiType>(VisibilityContext);

    return (
        <Arrow
            disabled={initComplete && isLastItemVisible}
            onClick={() => scrollNext()}
            testId="right-arrow"
        >
            <Icon icon="iconamoon:arrow-right-2-bold" />
        </Arrow>
    );
}

const HomeSearchOption = ({audioRef, selectedName}) => {
    const [activeOptionmain, setActiveOption] = useState("ask_nad_chat");
    const [introCompleted, setIntroCompleted] = useState<boolean>(localStorage.getItem("introBots") == "completed"); // Track if the intro.js tour has been completed

    const onExit = () => { localStorage.setItem("introBots", "completed"); setIntroCompleted(true); };

    const redirectUser = () => {
        audioRef.current?.pause();
        audioRef.current.currentTime = 0;
        window.open(`https://www.orbofi.com/chat/${selectedName}`, '_blank');
    };

    const dragState = useRef(new DragManager());

    const handleDrag = ({ scrollContainer }: typeof VisibilityContext) => (ev: React.MouseEvent) => {
        dragState.current.dragMove(ev, (posDiff) => {
            if (scrollContainer.current) {
                scrollContainer.current.scrollLeft += posDiff;
            }
        });
    };
    const onMouseDown = React.useCallback(() => dragState.current.dragStart, [dragState]);
    const onMouseUp = React.useCallback(() => dragState.current.dragStop, [dragState]);
    console.log({ dragState })
    return (
        <div className="ChatScreenOptions mt-2" onMouseLeave={dragState.current.dragStop}>
        <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={handleDrag}
            onWheel={onWheel}
        >
            <li><Button id='step1' variant="" className={activeOptionmain === "ask_and_chat" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
            </i> Ask and Chat</Button></li>
            <li><Button id='step2' variant="" className={activeOptionmain === "image_generation" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/pbhjpofq.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#4bb3fd,quaternary:#ffc738,quinary:#f24c00,senary:#ffffff,septenary:#f9c9c0"></LordIcon>
            </i>Image Generation</Button></li>
            <li><Button id='step3' variant="" className={activeOptionmain === "website-generation" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ddexfvmy.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
            </i> Website Generation </Button></li>
            <li><Button id='step4' variant="" className={activeOptionmain === "code" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ejcvkxer.json" trigger="loop" colors="primary:#ffffff,secondary:#ffc738,tertiary:#f24c00,quaternary:#54abb1,quinary:#4bb3fd,senary:#fafafa,septenary:#ebe6ef"></LordIcon>
            </i> Code </Button></li>
            <li><Button id='step5' variant="" className={activeOptionmain === "GPT-4" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ucekksbn.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
            </i> GPT-4 </Button><span className="protag"><Icon icon="noto-v1:crown" /></span></li>
            <li><Button variant="" className={activeOptionmain === "longform_text" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/pwwdvajw.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
            </i> Longform Text </Button></li>
            <li><Button id='step6' variant="" className={activeOptionmain === "vision_power" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/xkkkkglu.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#ffffff"></LordIcon>
            </i> Vision Power </Button><span className="protag"><Icon icon="noto-v1:crown" /></span></li>
            <div><Steps enabled={!(localStorage.getItem("introBots") == "completed")} steps={[]} initialStep={0} onExit={onExit} /></div>
        </ScrollMenu>
    </div>
    )
}

export default HomeSearchOption;