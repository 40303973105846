import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Form, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ChatbotData, ChatMessage, ClassifyVoice, containsImageRequest, convertMessagesForImageGeneration, convertMessagesForWait, convertMessagesShort, convertRAGMessages, formatTime, GenerateLongDescription, GetFeaturedChatbot, GetUserChatbotById, HasUserTalkedWithChatbot, ImageFileWithPreview, RegisterOrboxUse, SummarizeDescription, UpdateUserChatbot, uploadImageToS3, useChatScroll } from "./ChatbotUtils";
import UserChatbot from "./UserChatbot";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames-ts";
import tone from '/assets/bip-sound.mp3'
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import DictationComponent from "./Dictation";
// import DictateButton from 'react-dictate-button';
import { useSelector } from "react-redux";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { RUNPOD_BASE_URL, RUNPOD_ROMANIAN_SERVER } from "../HomeSearch/utils";
import axios from "axios";
import { SSE } from "sse";
import parse from "html-react-parser";
import Typewriter from "./Typewriter/Typewriter";
import DictateButton from "../MicrosoftDictatation/DictateButton";
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';

let refIndex = 0;
type ChatHistory = ChatMessage[];
let full_message = "";

const ChatbotBoxIframe = () => {
    const api_key = import.meta.env.VITE_OPENAI_API_KEY;
    let isAudioFinished = false;
    const numberOfRefs = 100;
    const [chatbotData, setChatbotData] = useState<ChatbotData | UserChatbot>();
    const [chatHistory, setChatHistory] = useState<ChatHistory>([]);
    const [isStreaming, setIsStreaming] = useState(Array.from({ length: numberOfRefs }, () => false));
    const [loading, setLoading] = useState<boolean>(false);
    const [imageFilesWithPreviews, setImageFilesWithPreviews] = useState<ImageFileWithPreview[]>([]);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isChatScreenAiAgentImgClicked, setIsChatScreenAiAgentImgClicked] = useState(0);
    const [activeTab, setActiveTab] = useState("static");
    const [talkingState, setTalkingState] = useState("idle");
    const [isMuted, setIsMuted] = useState(false);
    const userData = useSelector(userDataSelector);
    const [chatbotImage, setChatbotImage] = useState(null);
    const [showImagePreview, setShowImagePreview] = useState(true);
    const [audioSrc, setAudioSrc] = useState<string>("");
    const [isVoiceToVoice, setIsVoiceToVoice] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [stringToShow, setStringToShow] = useState("");
    const [currentIndexTextStream, setCurrentIndexTextStream] = useState(0);
    const [whileStreamingCurrentIndexTextStream, setWhileStreamingCurrentIndexTextStream] = useState(0);
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const [longGenerationWaiting, setLongGenerationWaiting] = useState(false);
    const [textStream, setTextStream] = useState([]);
    const [isRunning, setIsRunning] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeOption, setActiveOption] = useState("ask_and_chat");
    const [messagesCounter, setMessagesCounter] = useState(0);
    const [imageForS3Upload, setImageForS3Upload] = useState();
    const [inputPrompt, setInputPrompt] = useState("You are Danny Trejo, talk & act like the legendary danny trejo! You always have a positive attitude and if it makes sense!");
    const [selectedVoice, setSelectedVoice] = useState("");
    const [isLiked, setIsLiked] = useState(false);
    const [isVoiceClassified, setIsVoiceClassified] = useState(false);
    const [userHangUp, setUserHangUp] = useState(false);
    const [screenSize, setScreenSize] = useState(0);
    const [message, setMessage] = useState("");
    const [isListening, setIsListening] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [hasRan, setHasRan] = useState(false);
    const [summarizedDescription, setSummarizedDescription] = useState("");
    const [openAIChatHistory, setOpenAIChatHistory] = useState([]);
    const [speechServices, setSpeechServices] = useState(null);
    const [showHelloModal, setShowHello] = useState(true);
    const [hasUserChatted, setHasUserChatted] = useState(false);
    const SpeechGrammarList = speechServices ? speechServices.SpeechGrammarList : null;
    const SpeechRecognition = speechServices ? speechServices.SpeechRecognition : null;
    const [displayedText, setDisplayedText] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userChatbot = queryParams.get("userCreated") || true;
    const serializedData = 6869
    const chatbotId: number = Number(serializedData);
    const dictateButtonRef = useRef(null);
    const typeRef = useRef(null);

    const audioContainerRef = useRef(null);
    const resultRef = useRef(null);
    const imageRef = useRef(null);
    const refArray = Array.from({ length: numberOfRefs }, () => useRef());
    const chatFieldRef = useRef(null);

    useEffect(() => {
        const initializeSpeechServices = async () => {
          const services = await createPonyfill({
            credentials: {
              region: "germanywestcentral",
              subscriptionKey: "9a3cfaf40ad04b11a1f4ee74d56d4a25",
            },
          });
          setSpeechServices(services);
        };
    
        initializeSpeechServices();
    
      }, []);

    const onClickChatScreenAiAgentImg = (val: number) => {
        setIsChatScreenAiAgentImgClicked(val);
        setActiveTab("static");
    };

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    function addMessageToOpenAIChat(newMessage: ChatMessage) {
        setOpenAIChatHistory(prevChatHistory => [...prevChatHistory, newMessage]);
        // console.log(chatHistory);
    }

    useEffect(() => {
        if (isVoiceClassified) {
            sayHello()
        }
    }, [isVoiceClassified])

    const onChangePrinting = (text) => {
        setDisplayedText(text)
    }

    const handleTypingComplete = () => {
        setIsTypingComplete(true);
        setDisplayedText('')
        setIsStreamingForIndex(refIndex, false);
        const nextStringToShow = `${stringToShow}${textStream[currentIndexTextStream]}`;
        let nextStringToShowProcessed = `${stringToShow}${textStream[currentIndexTextStream]}`.replace(/\[END\]\d+/g, "");
        nextStringToShowProcessed = `${stringToShow}${textStream[currentIndexTextStream]}`.replace("[E", "");
        nextStringToShowProcessed = `${stringToShow}${textStream[currentIndexTextStream]}`.replace("ND]", "");

        const newChatMessage: ChatMessage = {
            text: nextStringToShowProcessed,
            sender: "system",
            profile: chatbotImage, // Replace with actual data
            audioUrl: "", // Replace with actual data
            time: formatTime(new Date()),
        };
        addMessageToOpenAIChat(newChatMessage);

        // toast.error('Typing complete');
    };

    const resetChat = () => {
        refIndex = 0
        setHasRan(false)
        // sayHello(false)
    }

    const toggleMute = () => {
        const currentlyMuted = !isMuted;
        setIsMuted(currentlyMuted);
        if (audioRef.current) {
            audioRef.current.muted = currentlyMuted;
        }
    };

    const handleLike = () => {
        if (isDisliked) {
            setIsDisliked(false);
        }
        setIsLiked(!isLiked);
    };

    const handleDislike = () => {
        if (isLiked) {
            setIsLiked(false);
        }
        setIsDisliked(!isDisliked);
    };

    const audiomain = () => {
        // startListening();
        // startListeningMain();
        console.log("audio effect playing");
        if (isListening) {
            setIsListening(false);
        } else {
            const audio = new Audio(tone);
            audio.volume = 0.03;
            audio.play();
            console.log(audio, "audio");
            console.log("audio effect playing");
        }
    }

    const isIphone = () => {
        return /iPhone/.test(navigator.userAgent);
    };

    function UpdateChatbot(chatbot: UserChatbot) {
        if (chatbot.user_id > 0) {
            UpdateUserChatbot(chatbot);
        }
        else {
            axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateChatbot`, chatbot);
        }
    }

    useEffect(() => {
        if (userChatbot === true) {
            GetUserChatbotById(chatbotId).then((chatbot: UserChatbot) => { InitializeChatbotData(chatbot); });
        }
        else {
            GetFeaturedChatbot(chatbotId).then((chatbot: ChatbotData) => { InitializeChatbotData(chatbot); });
        }
    }, [chatbotId]);

    function InitializeChatbotData(chatbot: ChatbotData | UserChatbot) {

        // if (chatbot.user_id === undefined) {
        //     // Scrapped chatbot
        //     HasUserTalkedWithChatbot(chatbot.chatbot_id).then((hasTalked: boolean) => {
        //         console.log("User Talked: " + hasTalked);
        //     });
        // }
        // else {
        //     // User chatbot
        //     HasUserTalkedWithChatbot(chatbot.id).then((hasTalked: boolean) => {
        //         console.log("User Talked: " + hasTalked);
        //     });
        // }
        let newData = false;
        setChatbotData(chatbot);
        setInputPrompt(chatbot.system_prompt.slice(0, 1500));
        if (!chatbot.audio_url || chatbot.audio_url.trim() === "") {
            newData = true
            ClassifyVoice(chatbot.system_prompt).then(audio_url => {
                chatbot.audio_url = audio_url;
                setSelectedVoice(chatbot.audio_url);
                setIsVoiceClassified(true);
                if (!chatbot.description || chatbot.description.trim() === "" || chatbot.description === "test") {
                    newData = true;
                    SummarizeDescription(chatbot.system_prompt).then(description => {
                        chatbot.description = description;
                        setSummarizedDescription(chatbot.description);
                        if ((chatbot.user_id === undefined) && (!chatbot.long_description || chatbot.long_description.trim() === "")) {
                            GenerateLongDescription(chatbot.system_prompt).then(long_description => {
                                chatbot.long_description = long_description;
                                UpdateChatbot(chatbot);
                            });
                        }
                        else {
                            UpdateChatbot(chatbot);
                        }
                    });
                }
                else {
                    setSummarizedDescription(chatbot.description);
                    UpdateChatbot(chatbot);
                }

            });
        }
        else {
            setSelectedVoice(chatbot.audio_url);
            setIsVoiceClassified(true);
            if (!chatbot.description || chatbot.description.trim() === "" || chatbot.description === "test") {
                newData = true;
                SummarizeDescription(chatbot.system_prompt).then(description => {
                    chatbot.description = description;
                    setSummarizedDescription(chatbot.description);
                    if ((chatbot.user_id === undefined) && (!chatbot.long_description || chatbot.long_description.trim() === "")) {
                        GenerateLongDescription(chatbot.system_prompt).then(long_description => {
                            chatbot.long_description = long_description;
                            UpdateChatbot(chatbot);
                        });
                    }
                    else {
                        UpdateChatbot(chatbot);
                    }
                });
            }
            else {
                setSummarizedDescription(chatbot.description);
                if ((chatbot.user_id === undefined) && (!chatbot.long_description || chatbot.long_description.trim() === "")) {
                    GenerateLongDescription(chatbot.system_prompt).then(long_description => {
                        chatbot.long_description = long_description;
                        UpdateChatbot(chatbot);
                    });
                }
            }
        }
    }

    const streamTextGeneration = async (isVoiceToVoiceActivated: boolean) => {

        // setIsListening(false);
        if (isVoiceToVoiceActivated) {
            setIsListening(true);
        }
        else {
            setIsListening(false);
        }
        var messageToSend = "";

        if (isStreaming[refIndex] == true) {
            // toast.error("Wait for generation to finish before starting a new one");
            // // setIsTyping(false);
            // return;
            isStreaming[refIndex] = false;
            toast.error("Stopping & sending new message");
        }

        if (hasRan) {
            refIndex += 1;
        }

        console.log({ isVoiceClassified })

        // if (isVoiceClassified == false) {
        //     toast.error("Voice not classified, try again in a moment or contact support");
        //     setIsTyping(false);
        //     return;
        // }


        if (full_message != "") {
            messageToSend = full_message;
        }
        else {
            messageToSend = message;
        }

        if (messageToSend.length < 1) {
            setTalkingState("idle");
            setIsVoiceToVoice(false);
            setIsListening(false);
            toast.error("Make sure input text is greater than 1 character", {
                icon: "👏",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                    // position: "bottom-center",
                    // index: '1'
                },
            });
            return;
        }

        let imageforVision = imageFilesWithPreviews[0]?.dataUrl

        console.log("image vision url")
        let s3Url;

        if (imageforVision) {

            setIsTyping(true);

            s3Url = await uploadImageToS3(imageForS3Upload)

        }

        const newChatHistory = [
            ...chatHistory,
            {
                text: messageToSend,
                sender: "user",
                profile: "/images/profile.png",
                // profile: userData?.ProfilePicture ?? "/images/profile.png",
                audioUrl: "",
                imageUrl: s3Url ? s3Url : ""
            },
        ];

        console.log("chat history", newChatHistory)

        setChatHistory(newChatHistory as []);

        setTalkingState("thinking");
        setStringToShow("");
        setCurrentIndexTextStream(0);
        setWhileStreamingCurrentIndexTextStream(0);
        setIsTypingComplete(false);
        setLongGenerationWaiting(true);
        setTextStream([]);
        setIsTyping(true);
        setMessage("");
        setImageFilesWithPreviews([])
        console.log({ message: containsImageRequest(message) })
        // If user wants to generate an image based off user query
        if (containsImageRequest(message)) {

            try {


                const myUUID = uuidv4();

                const isUserOnIphone = isIphone();

                if (isUserOnIphone) {
                    // toast.error("user on iphone");
                    const searchParams = new URLSearchParams();
                    searchParams.set("prompt", "");
                    searchParams.set("responseId", myUUID);
                    searchParams.set("history", JSON.stringify(convertMessagesForWait(newChatHistory, inputPrompt)));
                    searchParams.set("selectedVoice", selectedVoice);
                    searchParams.set("introMsg", "true");
                    searchParams.set("chatId", chatbotData.id);
                    searchParams.set("isRAG", chatbotData.rag);
                    searchParams.set("imageVision", "");
                    searchParams.set("isIphone", isIphone().toString());
                    console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

                    var src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                    // setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);


                    if (audioContainerRef.current) {

                        let audioElement = audioContainerRef.current.querySelector('audio');
                        let sourceElement = audioElement.querySelector('source');
                        sourceElement.src = src;
                        audioElement.load();

                        // Add ended event listener to remove the source
                        audioElement.addEventListener('ended', () => {
                            // audioElement.pause();
                            // audioContainerRef.current.innerHTML = '';
                        });

                        audioElement.addEventListener('loadeddata', () => {
                            audioElement.play();
                            // audioElement.src = '';
                            // setAudioSrc(null);
                            // toast.error("Audio loadeddata");


                            // audioContainerRef.current.innerHTML = '';
                        });

                        audioElement.addEventListener('canplay', () => {
                            // toast.error("Audio canplay");
                            // audioElement.src = '';
                            // setAudioSrc(null);
                            // toast.error("Audio canplay");
                            audioElement.play();
                        });

                        audioElement.addEventListener('progress', () => {
                            // audioElement.src = '';
                            // setAudioSrc(null);
                            // toast.error("Audio onprogress");
                            audioElement.play();
                        });

                        // Add canplaythrough event listener to play the audio when it is ready
                        audioElement.addEventListener('canplaythrough', () => {
                            audioElement.play();
                            // toast.error("Audio canplaythrough");
                        });

                        audioElement.addEventListener('loadedmetadata', () => {
                            // audioElement.src = '';
                            // setAudioSrc(null);
                            // toast.error("Audio loadedmetadata");
                            audioElement.play();
                        });

                        audioElement.addEventListener('play', () => {
                            // toast.error("Audio is playing");
                        });

                        // toast.error('6');
                        // audioElement.addEventListener('onloadeddata', () => {
                        //   if(isVoiceToVoiceActivated) {
                        //     console.log("Voice to voice activated");
                        //     const dictateButton = document.querySelector('.dictate-button');
                        //     if (dictateButton) {
                        //       dictateButton.click();
                        //     }
                        //     setTalkingState("listening");
                        //   }
                        //   // audioElement.src = '';
                        //   // setAudioSrc(null);
                        // });
                    }


                    // return () => {
                    //   audioRef.current.removeEventListener("loadedmetadata", playAudio);
                    //   audioRef.current.removeEventListener("error", onError);
                    //   audioRef.current.removeEventListener("ended", onEnd);

                    // };
                } else {
                    // toast.error("user not on iphone");
                    const searchParams = new URLSearchParams();
                    searchParams.set("prompt", "");
                    searchParams.set("responseId", myUUID);
                    searchParams.set("history", JSON.stringify(convertMessagesForWait(newChatHistory, inputPrompt)));

                    searchParams.set("selectedVoice", selectedVoice);
                    searchParams.set("introMsg", "true");
                    searchParams.set("chatId", chatbotData.id);
                    searchParams.set("isRAG", chatbotData.rag);
                    searchParams.set("imageVision", "");
                    console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

                    var url2 = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                    // Create a MediaSource
                    const mediaSource = new MediaSource();
                    const audioElement = audioRef.current;

                    // Set the audio source
                    console.log({ talkingState, saxsdasdasasas: true })
                    audioElement.src = URL.createObjectURL(mediaSource);
                    // console.log('MediaSource created and URL set');

                    mediaSource.addEventListener('sourceopen', async () => {
                        // console.log('MediaSource opened');
                        const mimeCodec = 'audio/mpeg';
                        if (MediaSource.isTypeSupported(mimeCodec)) {
                            // console.log(`${mimeCodec} is supported`);
                            const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                            sourceBuffer.addEventListener('updatestart', () => {
                                // console.log('SourceBuffer: updatestart');
                            });

                            sourceBuffer.addEventListener('updateend', () => {
                                // console.log('SourceBuffer: updateend');
                            });

                            sourceBuffer.addEventListener('error', (e) => {
                                // console.error('SourceBuffer error:', e);
                            });

                            // Fetch and stream the audio data
                            const response = await fetch(url2);
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            // console.log('Fetch response received');

                            const reader = response.body.getReader();

                            const processChunk = async ({ done, value }: any) => {
                                // console.log({ done, value, talkingState })
                                // setTalkingState("talking");
                                // if (done) {
                                //   setTalkingState("idle");
                                //   // console.log('Stream complete');
                                //   mediaSource.endOfStream();
                                //   return;
                                // }

                                // Wait for SourceBuffer to be ready
                                await new Promise<void>(resolve => {
                                    if (!sourceBuffer.updating) {
                                        resolve();
                                        return;
                                    }
                                    const onUpdateEnd = () => {
                                        sourceBuffer.removeEventListener('updateend', onUpdateEnd);
                                        resolve();
                                    };
                                    sourceBuffer.addEventListener('updateend', onUpdateEnd);
                                });

                                // console.log('Appending chunk to SourceBuffer');
                                sourceBuffer.appendBuffer(value);

                                // Read the next chunk
                                reader.read().then(processChunk);
                            };

                            // Start reading the first chunk
                            reader.read().then(processChunk);
                        } else {
                            console.error(`MIME type ${mimeCodec} is not supported`);
                        }
                    });

                    // Play the audio within the user interaction context
                    audioElement.play().then(() => {
                        console.log('Audio is playing');

                    }).catch(error => {
                        console.error('Playback error:', error);
                    });
                }
                setLoading(true);
                setMessage("");


                const newChatMessage: ChatMessage = {
                    text: (refIndex).toString(),
                    sender: "system",
                    profile: chatbotImage, // Replace with actual data
                    audioUrl: "", // Replace with actual data
                    time: formatTime(new Date()),
                };

                console.log("setIsStreamingForIndex", refIndex)
                // addMessageToChat(newChatMessage);

                setIsStreamingForIndex(refIndex, true);

                var isAdded = false;

                // Start a timer that fetches the dictionary from the Flask API every 1 second
                const intervalId = setInterval(async () => {
                    try {
                        const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`, {
                            method: "GET",
                            headers: {
                                "Accept": "application/json",
                            },
                        });
                        if (!response.ok) {
                            throw new Error(`Error fetching data: ${response.statusText}`);
                        }
                        const data = await response.json();

                        const data_array = data["array_data"];
                        const totalSentences = findEndNumber(data_array);
                        const newTextStream = data_array.join(" ");

                        resultRef.current = newTextStream;
                        refArray[refIndex].current = newTextStream;
                        console.log({ talkingState, data_array, resultRef })
                        setTextStream(data_array.join(" ").replace("Treho", "Trejo"));
                        // setIsTyping(false);

                        if (newTextStream.length > 1) {
                            setIsTyping(false);
                        }

                        if (totalSentences != null) {
                            if (!isAdded) {
                                isAdded = true;
                                addMessageToChat(newChatMessage);
                            }
                            clearInterval(intervalId);

                            refArray[refIndex].current = data_array.slice(0, -1).join(" ").replace("Treho", "Trejo");
                            setCurrentIndex(currentIndex + 1);
                            setMessagesCounter(messagesCounter + 1);
                            setTalkingState("idle");
                            setHasRan(true);
                            isAudioFinished = true;

                            // Ping data to server
                            await axios.post('https://98sylryzyqxvtf-6000.proxy.runpod.net/submit_qa', {
                                question: messageToSend,
                                response: data_array.slice(0, -1).join(" ").replace("Treho", "Trejo"),
                            });
                        }

                    } catch (error) {
                        console.error("Failed to fetch data:", error);
                    }
                }, 2000);



                // audioElement.load();


                // audioRef.current.onplay = () => {
                setTalkingState("talking");

                setIsTyping(true);

                const url = "https://api.openai.com/v1/chat/completions";
                const tmp = {
                    model: "gpt-3.5-turbo",
                    messages: convertMessagesForImageGeneration(newChatHistory, inputPrompt),
                    temperature: 0.75,
                    top_p: 0.95,
                    // max_tokens: 100,
                    stream: true,
                    // n: 1,
                };




                const source = new SSE(url, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: api_key,
                    },
                    method: "POST",
                    payload: JSON.stringify(tmp),
                });

                let full_text = "";

                source.addEventListener("message", (e: any) => {
                    setIsRunning(true);
                    console.log("scrollToBottom ====== >")
                    scrollToBottom();


                    if (e.data != "[DONE]") {

                        // setIsTyping(false);
                        const payload = JSON.parse(e.data);
                        const text = payload.choices[0].delta["content"];
                        if (text != null) {
                            full_text = full_text + text;
                        }

                    } else {
                        source.close();
                    }

                });
                let hasRun = false;
                source.addEventListener("readystatechange", async (e: any) => {
                    if (hasRun) return;
                    if (e.readyState >= 2) {

                        hasRun = true;

                        const prompts = extractListItems(full_text);
                        const images: any = [];

                        Promise.all(prompts.map((prompt: string) => {

                            const postData = {
                                model: "dall-e-3",
                                prompt: prompt,
                                n: 1,
                                size: "1024x1024"
                            };

                            return axios.post("https://api.openai.com/v1/images/generations", postData, {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": api_key
                                }
                            })
                                .then(response => {
                                    images.push(response.data.data[0].url);


                                })
                                .catch(error => {
                                    // Handle errors
                                    console.error("Error generating image for prompt:", prompt, error);
                                    setIsLoading(false);
                                });
                        }))
                            .then(async ([imageResponses]) => {
                                const newChatMessage: ChatMessage = {
                                    text: "",
                                    sender: "system",
                                    profile: chatbotImage, // Replace with actual data
                                    audioUrl: "", // Replace with actual data
                                    images: images,
                                    time: formatTime(new Date()),
                                };
                                addMessageToChat(newChatMessage);
                                setIsTyping(false);

                            })
                            .catch(error => {
                                // Handle any error from the entire Promise.all
                                setIsLoading(false);
                            });

                    }
                });


                return () => {
                    audioRef.current.addEventListener("loadedmetadata", playAudio);
                    audioRef.current.addEventListener("ended", onEnd);
                    audioRef.current.addEventListener("error", onError);

                };
            } catch (error) {
                onError();
            }



        }
        else {
            // if (!audioRef.current) return;
            const onError = () => {
                setLoading(false);
                if (screenSize > 575) {
                    setTalkingState("idle");
                }
                console.error("Error loading audio");
                toast.error("Error loading audio, browser not supported");
            };

            try {


                const myUUID = uuidv4();
                console.log({ myUUID, chatbotData })

                let convertedMessages = chatbotData?.rag ? convertRAGMessages(newChatHistory, inputPrompt, userData.Name) : convertMessagesShort(newChatHistory, inputPrompt, userData.Name);
                console.log({ convertedMessages })
                const isUserOnIphone = isIphone();
                console.log({ isUserOnIphone, myUUID, convertedMessages })

                if (isUserOnIphone) {

                    const searchParams = new URLSearchParams();
                    searchParams.set("prompt", "");
                    searchParams.set("responseId", myUUID);
                    searchParams.set("history", JSON.stringify(convertedMessages));
                    searchParams.set("selectedVoice", selectedVoice);
                    searchParams.set("introMsg", "true");
                    searchParams.set("chatId", chatbotData.id);
                    searchParams.set("isRAG", chatbotData.rag);
                    searchParams.set("imageVision", "");
                    searchParams.set("isIphone", isIphone().toString());
                    console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);


                    var src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;

                    console.log({ searchParams, src, audioContainerRef })

                    if (audioContainerRef.current) {

                        let audioElement = audioContainerRef.current.querySelector('audio');
                        let sourceElement = audioElement.querySelector('source');
                        sourceElement.src = src;
                        audioElement.load();

                        // Add ended event listener to remove the source
                        audioElement.addEventListener('ended', () => {
                        });

                        audioElement.addEventListener('loadeddata', () => {
                            audioElement.play();
                        });

                        audioElement.addEventListener('canplay', () => {
                            audioElement.play();
                        });

                        audioElement.addEventListener('progress', () => {
                            audioElement.play();
                        });

                        // Add canplaythrough event listener to play the audio when it is ready
                        audioElement.addEventListener('canplaythrough', () => {
                            audioElement.play();
                        });

                        audioElement.addEventListener('loadedmetadata', () => {
                            audioElement.play();
                        });

                        audioElement.addEventListener('play', () => {
                        });

                    }


                    setLoading(true);
                    // setMessage("");
                    // console.log({ talkingState, okokok: true})
                    setTalkingState("thinking");

                    // setIsTyping(false);

                    const newChatMessage: ChatMessage = {
                        text: (refIndex).toString(),
                        sender: "system",
                        profile: chatbotImage, // Replace with actual data
                        audioUrl: "", // Replace with actual data
                        time: formatTime(new Date()),
                    };

                    // toast.error('sent request');
                    // addMessageToChat(newChatMessage);

                    let isAdded = false;

                    setIsStreamingForIndex(refIndex, true);
                    // toast.error('7');
                    // Start a timer that fetches the dictionary from the Flask API every 1 second
                    const intervalId = setInterval(async () => {

                        try {
                            const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`, {
                                method: "GET",
                                headers: {
                                    "Accept": "application/json",
                                },
                            });
                            if (!response.ok) {
                                throw new Error(`Error fetching data: ${response.statusText}`);
                            }
                            const data = await response.json();

                            const data_array = data["array_data"];

                            const totalSentences = findEndNumber(data_array);
                            const newTextStream = data_array.join(" ");

                            resultRef.current = data_array.join(" ");
                            refArray[refIndex].current = data_array.join(" ");

                            setTextStream(data_array.join(" ").replace("Treho", "Trejo"));

                            // setIsTyping(false);
                            if (newTextStream.length > 1) {
                                setIsTyping(false);
                            }

                            if (totalSentences != null) {
                                // setAudioSrc("");
                                if (!isAdded) {
                                    isAdded = true;
                                    setIsTyping(false);
                                    addMessageToChat(newChatMessage);

                                }
                                clearInterval(intervalId);
                                refArray[refIndex].current = data_array.slice(0, -1).join(" ").replace("Treho", "Trejo");
                                console.log({ talkingState, sasasas: true })
                                if (screenSize > 575) {
                                    setTalkingState("idle");
                                }
                                setHasRan(true);
                                isAudioFinished = true;

                                // Ping data to server
                                await axios.post('https://98sylryzyqxvtf-6000.proxy.runpod.net/submit_qa', {
                                    question: messageToSend,
                                    response: data_array.slice(0, -1).join(" ").replace("Treho", "Trejo"),
                                });
                            }

                        } catch (error) {
                            console.error("Failed to fetch data:", error);
                        }
                    }, 2000);

                    return () => {

                    };

                } else {
                    // toast.error("user not on iphone");
                    const searchParams = new URLSearchParams();
                    searchParams.set("prompt", "");
                    searchParams.set("responseId", myUUID);
                    searchParams.set("history", JSON.stringify(convertedMessages));
                    searchParams.set("selectedVoice", selectedVoice);

                    searchParams.set("introMsg", "true");
                    searchParams.set("chatId", chatbotData.id);
                    searchParams.set("isRAG", chatbotData.rag);
                    searchParams.set("imageVision", "");
                    console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

                    var url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                    // Create a MediaSource
                    const mediaSource = new MediaSource();
                    console.log({ mediaSource, url })
                    const audioElement = audioRef.current;

                    // Set the audio source
                    console.log({ talkingState, saxsdasdasasas: true })
                    audioElement.src = URL.createObjectURL(mediaSource);
                    // console.log('MediaSource created and URL set');

                    mediaSource.addEventListener('sourceopen', async () => {
                        // console.log('MediaSource opened');
                        const mimeCodec = 'audio/mpeg';
                        if (MediaSource.isTypeSupported(mimeCodec)) {
                            // console.log(`${mimeCodec} is supported`);
                            const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                            sourceBuffer.addEventListener('updatestart', () => {
                                // console.log('SourceBuffer: updatestart');
                            });

                            sourceBuffer.addEventListener('updateend', () => {
                                // console.log('SourceBuffer: updateend');
                            });

                            sourceBuffer.addEventListener('error', (e) => {
                                // console.error('SourceBuffer error:', e);
                            });

                            // Fetch and stream the audio data
                            const response = await fetch(url);
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            // console.log('Fetch response received');

                            const reader = response.body.getReader();

                            const processChunk = async ({ done, value }: any) => {
                                // console.log({ done, value, talkingState })
                                setTalkingState("talking");
                                if (done) {
                                    setTalkingState("idle");
                                    // console.log('Stream complete');
                                    mediaSource.endOfStream();
                                    return;
                                }

                                // Wait for SourceBuffer to be ready
                                await new Promise<void>(resolve => {
                                    if (!sourceBuffer.updating) {
                                        resolve();
                                        return;
                                    }
                                    const onUpdateEnd = () => {
                                        sourceBuffer.removeEventListener('updateend', onUpdateEnd);
                                        resolve();
                                    };
                                    sourceBuffer.addEventListener('updateend', onUpdateEnd);
                                });

                                // console.log('Appending chunk to SourceBuffer');
                                sourceBuffer.appendBuffer(value);

                                // Read the next chunk
                                reader.read().then(processChunk);
                            };

                            // Start reading the first chunk
                            reader.read().then(processChunk);
                        } else {
                            console.error(`MIME type ${mimeCodec} is not supported`);
                        }
                    });

                    // Play the audio within the user interaction context
                    audioElement.play().then(() => {
                        console.log('Audio is playing');


                    }).catch(error => {
                        console.error('Playback error:', error);
                    });

                }



                setLoading(true);
                if (screenSize > 575) {
                    setMessage("");
                }
                setTalkingState("thinking");


                const newChatMessage: ChatMessage = {
                    text: (refIndex).toString(),
                    sender: "system",
                    profile: chatbotImage, // Replace with actual data
                    audioUrl: "", // Replace with actual data
                    time: formatTime(new Date()),
                };
                // addMessageToChat(newChatMessage);

                console.log("setIsStreamingForIndex ---1")

                setIsStreamingForIndex(refIndex, true);

                // Start a timer that fetches the dictionary from the Flask API every 1 second
                const intervalId = setInterval(async () => {
                    try {
                        const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`, {
                            method: "GET",
                            headers: {
                                "Accept": "application/json",
                            },
                        });
                        if (!response.ok) {
                            throw new Error(`Error fetching data: ${response.statusText}`);
                        }
                        const data = await response.json();

                        const data_array = data["array_data"];
                        const totalSentences = findEndNumber(data_array);
                        const newTextStream = data_array.join(" ");

                        resultRef.current = newTextStream;
                        refArray[refIndex].current = newTextStream;
                        console.log({ talkingState, data_array, resultRef })
                        setTextStream(data_array.join(" ").replace("Treho", "Trejo"));
                        scrollToBottom();
                        // setIsTyping(false);

                        if (newTextStream.length > 1) {
                            setIsTyping(false);
                        }

                        if (totalSentences != null) {
                            if (!isAdded) {
                                isAdded = true;
                                addMessageToChat(newChatMessage);
                                setIsTyping(false);
                            }
                            clearInterval(intervalId);

                            refArray[refIndex].current = data_array.slice(0, -1).join(" ").replace("Treho", "Trejo");
                            setCurrentIndex(currentIndex + 1);
                            setMessagesCounter(messagesCounter + 1);
                            setTalkingState("idle");
                            setHasRan(true);
                            isAudioFinished = true;

                            // Ping data to server
                            await axios.post('https://98sylryzyqxvtf-6000.proxy.runpod.net/submit_qa', {
                                question: messageToSend,
                                response: data_array.slice(0, -1).join(" ").replace("Treho", "Trejo"),
                            });
                        }

                    } catch (error) {
                        console.error("Failed to fetch data:", error);
                    }
                }, 2000);

            } catch (error) {
                toast.error(error);
                onError();
            }
        }

    };
    const scrollToBottom = () => {
        const chatField = chatFieldRef.current;
        if (chatField) {
            chatField.scrollTop = chatField.scrollHeight;
        }
    };

    useEffect(() => {
        const chatField = chatFieldRef.current;
        if (chatField) {
            scrollToBottom();
        }
    }, [chatHistory, textStream, displayedText]);

    const sayHello = async () => {
        // toast.error("Saying hello");
        setShowHello(false);
        setIsTyping(true);

        if (isStreaming[refIndex] == true) {
            toast.error("Wait for generation to finish before starting a new one");
            return;
        }

        if (hasRan) {
            refIndex += 1;
        }

        if (isVoiceClassified == false) {
            toast.error("Voice not classified, try again in a moment or contact support");
            return;
        }

        const newChatHistory = [

        ];
        setChatHistory(newChatHistory as []);
        console.log("starting");

        setTalkingState("thinking");
        setStringToShow("");
        setCurrentIndexTextStream(0);
        setTextStream([]);
        setIsTypingComplete(false)
        setLongGenerationWaiting(true);

        // setIsStreaming(true);


        const conversationHistory = [];

        var rag_intro = "";
        var normal_intro = "";

        if (hasUserChatted) {
            rag_intro = `Welcome the user back by saying something along the lines of "Welcome back ${userData.Name}". This is a returned conversation that you have with the user, so say hello as if you are meeting an old friend. Spark the conversation with an intriguing open ended question or statement that will get the user responding. Make the question specific. KEEP IT SHORT.`;
            normal_intro = `Welcome the user back by saying something along the lines of "Welcome back ${userData.Name}". This is a returned conversation that you have with the user, so say hello as if you are meeting an old friend. Spark the conversation with an intriguing open ended question or statement that will get the user responding. Make the question specific. KEEP IT SHORT.`
        }
        else {
            rag_intro = `Greet the user by saying "Hello ${userData.Name}", and briefly introduce yourself to the user and tell about knowledge/context that you have. Also talk about the various abilities you have including image generation, website generation, code generation & knowing all information ever. KEEP IT SHORT.`;
            normal_intro = `Greet the user by saying "Hello ${userData.Name}", and briefly introduce yourself to the user. Also talk about the various abilities you have including image generation, website generation, code generation & knowing all information ever. KEEP IT SHORT.`
        }

        console.log({ chatbotData })
        const openAIChatHistory2 = [
            ...conversationHistory,
            {
                text: chatbotData?.rag ? rag_intro : normal_intro,
                sender: "user",
                profile: "/images/profile.png",
                audioUrl: "",
            },
        ];

        console.log(openAIChatHistory2);

        // if (!audioRef.current) return;
        const onError = (error) => {
            console.trace();
            setLoading(false);
            setTalkingState("idle");
            toast.error(JSON.stringify(error).toString());
            console.error("Error loading audio");
            toast.error("Error loading audio, browser not supported");
        };

        try {


            const myUUID = uuidv4();
            let convertedMessages = chatbotData?.rag ? convertRAGMessages(openAIChatHistory2, inputPrompt, userData.Name) : convertMessagesShort(openAIChatHistory2, inputPrompt, userData.Name);

            const isUserOnIphone = isIphone();

            if (isUserOnIphone) {
                // toast.error("user on iphone");
                const searchParams = new URLSearchParams();
                searchParams.set("prompt", "");
                searchParams.set("responseId", myUUID);
                // toast.error(myUUID);
                searchParams.set("history", JSON.stringify(convertedMessages));
                searchParams.set("selectedVoice", selectedVoice);
                searchParams.set("introMsg", "true");
                searchParams.set("chatId", chatbotData.id);
                searchParams.set("isRAG", chatbotData.rag);
                searchParams.set("imageVision", "");
                searchParams.set("isIphone", isIphone().toString());
                console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);
                // toast.error("sending request");
                // toast.error(searchParams.get('responseId'));

                var src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                // setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);


                if (audioContainerRef.current) {
                    // Remove existing audio element if any
                    audioContainerRef.current.innerHTML = '';

                    // Create a new audio element
                    const audioElement = document.createElement('audio');
                    audioElement.className = 'w-full';
                    audioElement.controls = true;
                    audioElement.autoplay = true;

                    // Create a source element
                    const sourceElement = document.createElement('source');
                    sourceElement.src = src;
                    sourceElement.type = 'audio/mpeg';

                    // Append the source to the audio element
                    audioElement.appendChild(sourceElement);

                    // Append the audio element to the container
                    audioContainerRef.current.appendChild(audioElement);

                    // Load and play the audio
                    audioElement.load();
                    // Add canplay event listener to play the audio when it is ready
                    audioElement.addEventListener('ended', () => {
                        // audioContainerRef.current.innerHTML = '';
                    });

                    audioElement.addEventListener('loadeddata', () => {

                        // audioElement.src = '';
                        // setAudioSrc(null);
                        // toast.error("Audio loadeddata");


                        // audioContainerRef.current.innerHTML = '';
                    });

                    audioElement.addEventListener('canplay', () => {
                        // toast.error("Audio canplay");
                        // audioElement.src = '';
                        // setAudioSrc(null);
                        // toast.error("Audio canplay");
                    });

                    audioElement.addEventListener('progress', () => {
                        // audioElement.src = '';
                        // setAudioSrc(null);
                        // toast.error("Audio onprogress");
                    });

                    audioElement.addEventListener('loadedmetadata', () => {
                        // audioElement.src = '';
                        // setAudioSrc(null);
                        // toast.error("Audio loadedmetadata");
                        audioElement.play();

                    });

                    audioElement.addEventListener('play', () => {
                        // toast.error("Audio is playing");
                    });
                }

                setLoading(true);
                // setMessage("");
                setTalkingState("thinking");
                // setIsTyping(false);

                const newChatMessage: ChatMessage = {
                    text: (refIndex).toString(),
                    sender: "system",
                    profile: chatbotImage, // Replace with actual data
                    audioUrl: "", // Replace with actual data
                    time: formatTime(new Date()),
                };

                console.log('sent request');
                // addMessageToChat(newChatMessage);

                let isAdded = false;

                setIsStreamingForIndex(refIndex, true);

                // Start a timer that fetches the dictionary from the Flask API every 1 second
                const intervalId = setInterval(async () => {

                    try {
                        const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`, {
                            method: "GET",
                            headers: {
                                "Accept": "application/json",
                            },
                        });
                        if (!response.ok) {
                            throw new Error(`Error fetching data: ${response.statusText}`);
                        }
                        const data = await response.json();

                        const data_array = data["array_data"];

                        const totalSentences = findEndNumber(data_array);
                        const newTextStream = data_array.join(" ");

                        if (newTextStream.length > 1) {
                            setIsTyping(false);
                        }

                        resultRef.current = data_array.join(" ");
                        refArray[refIndex].current = data_array.join(" ");

                        console.log({ data_array })

                        setTextStream(data_array.join(" ").replace("Treho", "Trejo"));

                        if (totalSentences != null) {


                            // setAudioSrc("");
                            if (!isAdded) {
                                isAdded = true;
                                setIsTyping(false);
                                addMessageToChat(newChatMessage);
                                // setAudioSrc("");
                                // audioRef.current.src = "";

                            }
                            clearInterval(intervalId);
                            refArray[refIndex].current = data_array.slice(0, -1).join(" ").replace("Treho", "Trejo");
                            if (screenSize > 575) {
                                setTalkingState("idle");
                            }
                            setHasRan(true);
                            isAudioFinished = true;
                        }

                    } catch (error) {
                        console.error("Failed to fetch data:", error);
                    }
                }, 2000);

                return () => {
                };
            } else {
                // toast.error("user not on iphone");
                const searchParams = new URLSearchParams();
                searchParams.set("prompt", "");
                searchParams.set("responseId", myUUID);
                searchParams.set("history", JSON.stringify(convertedMessages));
                searchParams.set("selectedVoice", selectedVoice);
                searchParams.set("introMsg", "true");
                searchParams.set("chatId", chatbotData.id);
                searchParams.set("isRAG", chatbotData.rag);
                searchParams.set("imageVision", "");
                console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

                // var url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                var url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
                
                // Create a MediaSource
                const mediaSource = new MediaSource();
                const audioElement = audioRef.current;

                // Set the audio source
                audioElement.src = URL.createObjectURL(mediaSource);
                console.log('MediaSource created and URL set');

                mediaSource.addEventListener('sourceopen', async () => {
                    console.log('MediaSource opened');
                    const mimeCodec = 'audio/mpeg';
                    if (MediaSource.isTypeSupported(mimeCodec)) {
                        // console.log(`${mimeCodec} is supported`);
                        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                        sourceBuffer.addEventListener('updatestart', () => {
                            // console.log('SourceBuffer: updatestart');
                        });

                        sourceBuffer.addEventListener('updateend', () => {
                            // console.log('SourceBuffer: updateend');
                        });

                        sourceBuffer.addEventListener('error', (e) => {
                            // console.error('SourceBuffer error:', e);
                        });

                        // Fetch and stream the audio data
                        const response = await fetch(url);
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        console.log('Fetch response received');

                        const reader = response.body.getReader();

                        const processChunk = async ({ done, value }) => {
                            if (done) {
                                // console.log('Stream complete');
                                mediaSource.endOfStream();
                                return;
                            }

                            // Wait for SourceBuffer to be ready
                            await new Promise<void>(resolve => {
                                if (!sourceBuffer.updating) {
                                    resolve();
                                    return;
                                }
                                const onUpdateEnd = () => {
                                    sourceBuffer.removeEventListener('updateend', onUpdateEnd);
                                    resolve();
                                };
                                sourceBuffer.addEventListener('updateend', onUpdateEnd);
                            });

                            // console.log('Appending chunk to SourceBuffer');
                            sourceBuffer.appendBuffer(value);

                            // Read the next chunk
                            reader.read().then(processChunk);
                        };

                        // Start reading the first chunk
                        reader.read().then(processChunk);
                    } else {
                        console.error(`MIME type ${mimeCodec} is not supported`);
                    }
                });

                // Play the audio within the user interaction context
                audioElement.play().then(() => {
                    console.log('Audio is playing');
                }).catch(error => {
                    console.error('Playback error:', error);
                });
            }
            setLoading(true);
            // setMessage("");
            setTalkingState("thinking");
            // setIsTyping(false);

            const newChatMessage: ChatMessage = {
                text: (refIndex).toString(),
                sender: "system",
                profile: chatbotImage, // Replace with actual data
                audioUrl: "", // Replace with actual data
                time: formatTime(new Date()),
            };

            console.log('sent request');
            // addMessageToChat(newChatMessage);

            let isAdded = false;
            console.log({})

            setIsStreamingForIndex(refIndex, true);

            // Start a timer that fetches the dictionary from the Flask API every 1 second
            const intervalId = setInterval(async () => {

                try {
                    const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`, {
                        method: "GET",
                        headers: {
                            "Accept": "application/json",
                        },
                    });
                    if (!response.ok) {
                        throw new Error(`Error fetching data: ${response.statusText}`);
                    }
                    const data = await response.json();

                    const data_array = data["array_data"];

                    const totalSentences = findEndNumber(data_array);

                    const newTextStream = data_array.join(" ");

                    if (newTextStream.length > 1) {
                        setIsTyping(false);
                    }

                    resultRef.current = data_array.join(" ");
                    refArray[refIndex].current = data_array.join(" ");

                    setTextStream(data_array.join(" ").replace("Treho", "Trejo"));
                    // setIsTyping(false);

                    if (totalSentences != null) {
                        if (!isAdded) {
                            isAdded = true;
                            setIsTyping(false);
                            addMessageToChat(newChatMessage);

                        }
                        clearInterval(intervalId);
                        refArray[refIndex].current = data_array.slice(0, -1).join(" ").replace("Treho", "Trejo");
                        if (screenSize > 575) {
                            setTalkingState("idle");
                        }
                        setHasRan(true);
                        isAudioFinished = true;
                    }

                } catch (error) {
                    console.error("Failed to fetch data:", error);
                }
            }, 1000);

        } catch (error) {
            onError();
        }
    };

    function addMessageToChat(newMessage: ChatMessage) {
        setChatHistory(prevChatHistory => [...prevChatHistory, newMessage]);
        // console.log(chatHistory);
    }

    const setIsStreamingForIndex = (index: any, value: any) => {
        const updatedIsStreaming = [...isStreaming];
        updatedIsStreaming[index] = value;
        setIsStreaming(updatedIsStreaming);
    };

    function findEndNumber(arr: any) {
        // Regular expression to match '[END]' followed by any number of digits
        const regex = /\[END\](\d+)/;

        // Find the first element that matches the pattern and return the number after '[END]'
        for (const element of arr) {
            const match = element.match(regex);
            if (match) {
                return parseInt(match[1], 10); // Convert the matched number to an integer and return it
            }
        }

        // Return null if no match is found
        return null;
    }

    const updateMessageToSend = (text: any) => {
        full_message = text;
        console.log("Updated messageToSend:", full_message); // For debugging
    };

    const removeImage = (imageIndex: number) => {
        setImageFilesWithPreviews(prevImages => {
            URL.revokeObjectURL(prevImages[imageIndex].dataUrl);
            return prevImages.filter((_, index) => index !== imageIndex);
        });
    };

    const handleTextareaChange = (event: { target: { value: any; }; }) => {
        setMessage(event.target.value);
    };

    const handleSendClick = async () => {


        const AUDIO_ORBOX_QUANTITY = 1;
        console.log({ AUDIO_ORBOX_QUANTITY, activeOption, isChatScreenAiAgentImgClicked })

        if (activeOption === "ask_and_chat") {

            if (isChatScreenAiAgentImgClicked === 0 || isChatScreenAiAgentImgClicked == 3) {
                streamTextGeneration(false);
            }
            else {
                streamTextGeneration(false);
                // Removed because it is not working
                // streamTextGenerationText();
            }
            // await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.AskAndChat).then(() => { setDirty(true); });

        }

        setMessage("");
        setShowImagePreview(false); // Hide the image preview
    };

    const handleDictate = ({ result }: any) => {
        console.log({ result })
        if (isListening == true) {
            try {
                updateMessageToSend(result.transcript);

                setIsVoiceToVoice(true);
                setMessage(result.transcript);
                streamTextGeneration(true);
            } catch (error) {
                console.log("Error in handleDictate:", error);
            }
        }

    };

    const handleProgress = ({ results }) => {
        try {
            console.log(userHangUp);
            if (userHangUp == true) {
                setIsListening(false);
                return;
            }
            console.log("handleProgress");
            setIsListening(true);
            const interimTranscript = results?.map((result: any) => result.transcript).join(' ');
            setMessage(interimTranscript);
        } catch (error) {
            console.error("Error in handleProgress:", error);
        }
    };

    const handleHangup = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        onClickChatScreenAiAgentImg(0);
        setUserHangUp(true);
        setIsListening(false);
    };
    return (
        <div className="chatbotboxmain">
            <div className="chatbotHeader">
                <div className="MobileChatProfileImg">
                    <i><Image src={chatbotData?.img} /></i>
                    <h6>{chatbotData?.name}</h6>
                </div>
                <ul className="ChatScreenOptionSetting">
                    <li>
                        <Button variant="" className={isChatScreenAiAgentImgClicked === 0 ? "active" : ""} onClick={() => onClickChatScreenAiAgentImg(0)}><Icon icon="ep:refresh" /></Button>
                    </li>
                    <li>
                        <Button variant="" className={isChatScreenAiAgentImgClicked === 3 ? "active" : ""} onClick={() => onClickChatScreenAiAgentImg(3)}><Icon icon="material-symbols:call" /></Button>
                    </li>
                </ul>
            </div>
            <div className={classNames("ChatScreenWrap", {
                "ChatScreenWrapShow": isChatScreenAiAgentImgClicked === 0,
                "ChatScreenCodeShow": isChatScreenAiAgentImgClicked === 2,
                "ChatScreenLive": isChatScreenAiAgentImgClicked === 3,
                "ChatHistory": isChatScreenAiAgentImgClicked === 4,
            })}>
                <div
                    className={`FCompanions-Main FCompanionslive live-active ${activeTab === "live" ? "live-active" : ""}`}>
                    {/* {console.log({ chatbotData234163541: chatbotData })} */}
                    {activeTab === "static" ? (<div className="FCompanions-Img">
                        <Image className="FCompanions-img-div" src={chatbotData?.img} />
                        <div className="timeandicons d-flex">
                            <div className="microphoneandpauseicon">

                                <Link to="javascript:void(0)" className={isChatScreenAiAgentImgClicked === 1 ? "active btn btn-danger" : "closeicon btncallcut"} onClick={() => handleHangup()}>
                                    <lord-icon
                                        src="https://cdn.lordicon.com/nxjpnjjf.json"
                                        // trigger="loop"
                                        colors="primary:#ffffff"

                                    >
                                    </lord-icon>
                                </Link>
                            </div>
                            <div className="microphoneandpauseicon mr-0">
                                <span onClick={audiomain} className="btncallreceive">
                                    <DictateButton
                                        ref={dictateButtonRef}
                                        onDictate={handleDictate}
                                        onProgress={handleProgress}
                                        speechGrammarList={SpeechGrammarList}
                                        speechRecognition={SpeechRecognition}
                                    >
                                        <lord-icon
                                            src="https://cdn.lordicon.com/nxjpnjjf.json"
                                            trigger="loop"
                                            colors="primary:#ffffff"

                                        >
                                        </lord-icon>
                                    </DictateButton>

                                </span>
                            </div>

                        </div>


                        <div className="AudioSettingShow">
                            {talkingState === "idle" ? <p>
                                {/* <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> */}
                            </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                            {talkingState === "talking" ? <p>
                                <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe>
                            </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                            {talkingState === "listening" ? <p></p> :
                                <div>{/* content to render when talkingState is not idle */}</div>}
                            {talkingState === "thinking" ? <p>
                            </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                        </div>

                    </div>) : (


                        <div className="FCompanions-Img" ref={imageRef}>

                            <Image className="FCompanions-img-div" style={{ borderRadius: '15px', }}
                                src={chatbotData?.img} />
                            <div className="AudioSettingShow">
                                {talkingState === "idle" ? <p>

                                </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                                {talkingState === "talking" ? <p>
                                    <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe>


                                </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                                {talkingState === "listening" ? <p></p> :
                                    <div>{/* content to render when talkingState is not idle */}</div>}
                                {talkingState === "thinking" ? <p>

                                    {/* <div className="typing">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> */}
                                </p> : <div>{/* content to render when talkingState is not idle */}</div>}
                            </div>
                        </div>)}
                </div>

                <div ref={chatFieldRef} className="ImageUploadPreviewChat">
                    {chatHistory.map((msg, i) => (
                        <div key={i} className="chatmain"> {/* It's important to provide a unique key for each child in a list */}
                            {msg.sender === "user" ? (

                                <div className='ChatScreenMsg ChatScreenMsgLeft'>
                                    <div className='ChatScreenMsgBox'>
                                        {msg.imageUrl ? <div className="imgborder">
                                            <Image
                                                src={msg.imageUrl}
                                                style={{ maxWidth: "100%", height: "auto" }}
                                            />
                                        </div> : ''}
                                        <p>{msg.text}</p>
                                    </div>
                                    <small>{msg.time}</small>
                                </div>
                            ) : (
                                <div className='ChatScreenMsg ChatScreenMsgRight'>
                                    {isStreaming[parseInt(msg.text)] ? null : <div className='ChatScreenMsgWrap'>
                                        <i><img src={chatbotData?.img} alt="" /></i>

                                        <div className='ChatScreenMsgWrapBox'>
                                            {/* <div  style={{display:'inline'}}> */}
                                            <h6 style={{ display: 'inline' }}>{chatbotData.name}</h6>
                                            <button onClick={toggleMute} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#adb5bd' }}>
                                                {isMuted ? <Icon icon="lets-icons:sound-mute-duotone" color="red" /> : <Icon icon="lets-icons:sound-max-duotone" />}
                                            </button>
                                            {/* </div> */}

                                            {isNaN(parseInt(msg.text)) ? (
                                                <></>
                                                // <div className='ChatScreenMsgBox'>

                                                //   <p>{msg.text}</p>

                                                // </div>
                                            ) : (
                                                refArray[parseInt(msg.text)] && refArray[parseInt(msg.text)].current != null ? (
                                                    <div className='ChatScreenMsgBox'>
                                                        {isStreaming[parseInt(msg.text)] ? null : (
                                                            <p>{parse(refArray[parseInt(msg.text)].current) ?? "error"}</p>)}

                                                    </div>
                                                ) : null
                                            )}
                                            <div className="timeandicons d-flex justify-content-end">
                                                <ul className="chaticons">
                                                    <li>
                                                        <Link to="javascript:void(0)" onClick={handleLike}>
                                                            <Icon icon={isLiked ? "iconamoon:like-fill" : "iconamoon:like-thin"} />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="javascript:void(0)" onClick={handleDislike}>
                                                            <Icon icon={isDisliked ? "iconamoon:dislike-fill" : "iconamoon:dislike-thin"} />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="javascript:void(0)" onClick={() => {
                                                            navigator.clipboard.writeText(parse(refArray[parseInt(msg.text)].current).children)
                                                            toast.success('Text copied!')
                                                        }}>
                                                            <Icon icon="iconoir:copy" />
                                                        </Link>
                                                    </li>

                                                </ul>

                                                <small>{msg.time}</small>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            )}
                        </div>

                    ))}
                    {textStream && longGenerationWaiting && isTyping == false && isTypingComplete == false ? (<div>
                        <div className='ChatScreenMsg ChatScreenMsgRight'>
                            <div className='ChatScreenMsgWrap'>
                                <i><img src={chatbotData?.img} alt="" /></i>
                                <div className='ChatScreenMsgWrapBox'>
                                    <h6 style={{ display: 'inline' }}>{chatbotData.name}</h6>
                                    <button onClick={toggleMute} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#adb5bd' }}>
                                        {isMuted ? <Icon icon="lets-icons:sound-mute-duotone" color="red" /> : <Icon icon="lets-icons:sound-max-duotone" />}
                                    </button>
                                    <div className='ChatScreenMsgBox'>
                                        {/* <p> {showingThisText.toString()}</p> */}
                                        <Typewriter ref={typeRef} text={textStream} speed={40} onComplete={handleTypingComplete} onChange={onChangePrinting} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>) : null}
                    {isTyping && chatbotData?.img != undefined ? (
                        <div className="ChatScreenMsgWrap mt-2" style={{ textAlign: "left" }}>
                            <i>
                                <img
                                    src={chatbotData.img}
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                    }}
                                />
                            </i>{" "}
                            <div style={{ display: "inline-block" }}>
                                {/* <i><img src={chatbotData.img} alt="" /></i> */}
                                <div className='ChatScreenMsgWrapBox' >
                                    <h6 className="mb-0">{chatbotData.name}</h6>
                                    <img
                                        src={
                                            "/assets/loading.gif"
                                        }
                                    ></img>
                                </div>
                                {" "}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className='ChatScreenFooterMain'>
                <Link to="javascript:void(0)" className='Chat-popup-recorder' onClick={audiomain}>
                    <DictationComponent subscriptionKey="4ad414dbae5345908b65ea9e16cc11ca" region="eastus" setMessage={setMessage} streamTextGeneration={streamTextGeneration} updateMessageToSend={updateMessageToSend} isListening={isListening} setIsListening={setIsListening} setIsVoiceToVoice={setIsVoiceToVoice} userHangUp={userHangUp} />
                </Link>

                {/* <ImageUpload setReferenceImage={handleFileSelect} /> */}
                <div className='Chat-msg-textarea'>

                    {/* {imageFilesWithPreviews.length > 0 && <ul className="ChatImgUploadWrap">
                                {showImagePreview && imageFilesWithPreviews.map((fileWithPreview, index) => (
                                    <li key={index}>
                                        <span><img src={fileWithPreview.dataUrl} alt="Preview" /></span>
                                        <button type="button" onClick={() => removeImage(index)}>
                                            <Icon icon="iconamoon:close" />
                                        </button>
                                    </li>
                                ))
                                }
                            </ul>} */}
                    {isListening ? (

                        <Form.Control as="textarea" rows={1} placeholder="" value={message} onChange={handleTextareaChange} onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendClick();
                            }
                        }} />

                    ) : (
                        <Form.Control as="textarea" rows={1} placeholder="type_message" value={message} onChange={handleTextareaChange} onKeyPress={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendClick();
                            }
                        }} />
                    )}
                </div>
                <Button variant="" onClick={handleSendClick} className="Chat-msg-send-btn">
                    <Icon icon="ph:paper-plane-right" />
                </Button>
                <audio className="w-full" ref={audioRef} src={audioSrc} controls style={{ display: "none" }}>
                    {/* <source src={audioSrc} type="audio/mpeg" /> */}
                </audio>
            </div>
        </div>
    )
}

export default ChatbotBoxIframe;