import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChatbotBox from "./ChatbotBox";
import { useState } from "react";
import './Chatpopup.css'

const Chatpopup = () => {
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    return (
        <div className={`ChatpopupMain ${isToggled ? 'toggled' : ''}`}>
            <Link className="ChatbotpopIcon" onClick={handleToggle} to="#"><Image src="/assets/logo-icon.svg" /></Link>
            {isToggled && <ChatbotBox />}
        </div>
    )
}

export default Chatpopup;