import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './ArtificialIntelligence.css'

const ArtificialIntelligence = () => {
    return (
        <section className='ArtificialIntelligence-Main py-100 bg-blue'>
            <Container>
                <Row className='align-items-center'>
                    <Col lg={7}>
                        <div className='ArtificialIntelligence-img' data-aos="fade-up">
                            <Image src='/assets/ArtificialIntelligence-img.png' />
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className='ArtificialIntelligence-Info'>
                            <h2 data-aos="fade-up" className='main-title text-white'>The LEGOs of AI models</h2>
                            <p data-aos="fade-up">Orbofi is the most interact way to use with all the most powerful AI models, and any finetuned models, powered your AI companions and agents as LEGO blocks, personified and voiced by anyone</p>
                            <Link data-aos="fade-up" to="https://www.orbofi.com/" className='btn-white btn-white-icon btn '>Get Started <i><Icon icon="quill:arrow-right" /></i></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default ArtificialIntelligence;
