import { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
    const [scrollY, setScrollY] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();
    console.log(location)

    useEffect(() => {
        let lastScrollY = window.scrollY;
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const currentScrollY = window.scrollY;
                    setIsVisible(lastScrollY > currentScrollY || currentScrollY < 10);
                    setIsScrolled(currentScrollY > 50);
                    lastScrollY = currentScrollY;
                    ticking = false;
                });

                ticking = true;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollY]);

    return (
        <header className={`header ${isVisible ? 'show' : 'hide'} ${isScrolled ? 'scrolled' : ''}`}>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#"><Image src='/assets/logo.svg' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav activeKey={location.hash} className="ms-auto align-items-center" navbarScroll>
                            <Nav.Link href="#UseCases">Use cases</Nav.Link>
                            <Nav.Link href="#Features">Features</Nav.Link>
                            <Nav.Link href="#AIClones">AI clones</Nav.Link>
                            {/* <Nav.Link href="#Developers">Developers</Nav.Link> */}
                            <Nav.Link href="#OBIToken">OBI token</Nav.Link>
                            <Link to="https://www.orbofi.com/" className='btn-blue'>Enter App</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
