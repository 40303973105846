import React, { useState, useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import './CompanionsVoice.css';
import { Icon } from '@iconify/react';

const LazyLoadVideo = ({ url }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load only once when it comes into view
    threshold: 0.25, // Adjust threshold as needed
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [inView]);

  return (
    <div ref={ref} className='CompanionsVoice-video' data-aos="fade-up">
      {inView ? (
        <ReactPlayer
          width='100%'
          height='100%'
          playing={isPlaying}
          muted={true}
          loop={true}
          controls={false}
          url={url}
        />
      ) : (
        <div style={{ width: '100%', height: '100%' }} className='CompanionsVoice-video' data-aos="fade-up">
          <Image src='/assets/banner-video.mp4' />
        </div>
      )}
    </div>
  );
};

const CompanionsVoice = () => {
  return (
    <section className='CompanionsVoice-Main py-100'>
      <Container>
        <Row>
          <Col lg={5} md={6}>
            <div data-aos="fade-up" className='CompanionsVoice-Main-info mb-3 mb-lg-0 mb-md-0'>
              <h2 className='main-title text-white'>Integrate your voice AI companions, anywhere they can be plugged in</h2>
              <p  className='text-white'>Create and integrate your voice AI companions or agents, trained on your own data, to any application, website, NPC, piece of hardware, or anything you can hack out of it.</p>
              <div className='btn-wrap'>
                <Link to="#" className='btn-blue btn-blue-icon me-3'>Build with the API (soon) <i><Icon icon="mdi:sparkles" className='ms-1' /></i></Link>
                <Link to="https://wiki.orbofi.com/learn" className='btn-white btn-white-icon'>Learn more <i><Icon icon="mdi:sparkles" /></i></Link>
              </div>
            </div>
          </Col>
          <Col lg={7} md={6}>
            <div className='CompanionsVoice-Main-video'>
              <LazyLoadVideo url='/assets/banner-video.mp4' />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CompanionsVoice;
