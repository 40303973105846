import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './JoinOurCommunity.css'
import { Icon } from '@iconify/react';
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const LazyLoadVideo = ({ url }) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Load only once when it comes into view
        threshold: 0.25, // Adjust threshold as needed
    });
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    }, [inView]);

    return (
        <div ref={ref} className='JoinOurCommunity-video' data-aos="fade-up">
            {inView ? (
                <ReactPlayer
                    width='100%'
                    height='100%'
                    playing={isPlaying}
                    muted={true}
                    loop={true}
                    controls={false}
                    url={url}
                />
            ) : (
                <div style={{ width: '100%', height: '100%' }} className='JoinOurCommunity-video' data-aos="fade-up">
                    <Image src='/assets/JoinOurCommunity-img.png' />
                </div>
            )}
        </div>
    );
};


const JoinOurCommunity = () => {
    return (
        <section className='JoinOurCommunity-Main py-100'>
            <Container>
                <Row className='align-items-center justify-content-center'>
                    <Col lg={6}>
                        <div className='JoinOurCommunity-Info'>
                            <h2 data-aos="fade-up" className='main-title'>Join our community</h2>
                            <p data-aos="fade-up">Join our community of Creators, Developers, Researches, Degen, Traders, and Individuals from around the world who turning their imaginations into reality.</p>
                            <ul className='footer-social'>
                                <li data-aos="fade-up"><Link to="https://x.com/Orbofi?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><Icon icon="ri:twitter-x-fill" /></Link></li>
                                <li data-aos="fade-up"><Link to="https://t.me/orbofi"><Icon icon="mingcute:telegram-fill" /></Link></li>
                                <li data-aos="fade-up"><Link to="https://discord.com/invite/orbofi"><Icon icon="mingcute:discord-fill" /></Link></li>
                                <li data-aos="fade-up"><Link to="https://www.linkedin.com/company/orbofi"><Icon icon="ri:linkedin-fill" /></Link></li>
                                <li data-aos="fade-up"><Link to="https://www.reddit.com/r/Orbofi/"><Icon icon="ri:instagram-fill" /></Link></li>
                                <li data-aos="fade-up"><Link to="https://www.instagram.com/orbofi/"><Icon icon="ri:reddit-fill" /></Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className='JoinOurCommunity-img'>
                            <LazyLoadVideo url='/assets/JoinOurCommunity.mp4' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default JoinOurCommunity;
