import React from 'react';
import DictateButton from 'react-dictate-button';
import { Icon } from '@iconify/react';
import Select, { ActionMeta } from "react-select";
import OptionTypeBase from "react-select";
import toast from "react-hot-toast";
import tone from '/assets/bip-sound.mp3'
import { data } from './jsonData';
import { useEffect, useRef, useState } from 'react';
import { convertMessages, RUNPOD_BASE_URL } from "./utils";
import { Button, Image } from 'react-bootstrap';
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import './HomeSearch.css'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { publicApiType } from 'react-horizontal-scrolling-menu';
import { Steps } from "intro.js-react";
import HomeSearchOption from './HomeSearchOption';

interface CustomOptionType {
    id: number; name: string; img: string; audio_url: string; description: string;
}

const HomeSearch = () => {
    const audioRef: React.RefObject<HTMLAudioElement> = useRef<HTMLAudioElement>(null);
    const [introCompleted, setIntroCompleted] = useState<boolean>(localStorage.getItem("introBots") == "completed"); // Track if the intro.js tour has been completed
    const [selectedAudio, setSelectedAudio] = useState(data[0]['audio_url']);
    const [selectedInputPrompt, setSelectedInputPrompt] = useState(data[0]['description']);
    const [selectedId, setSelectedId] = useState(data[0]['id']);
    const [selectedName, setSelectedName] = useState(data[0]['name']);
    const [selectedOption, setSelectedOption] = useState(data[0]);
    const [talkingState, setTalkingState] = useState("idle");
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [toastShown, setToastShown] = useState(false);
    const [audioSrc, setAudioSrc] = useState<string>("");
    const [speechServices, setSpeechServices] = useState(null);
    const onEndExecuted = useRef(false);
    const [isListening, setIsListening] = useState(false);

    useEffect(() => {
        const initializeSpeechServices = async () => {
            const services = await createPonyfill({
                credentials: {
                    region: "eastus",
                    subscriptionKey: "4ad414dbae5345908b65ea9e16cc11ca",
                },
            });
            setSpeechServices(services);
        };

        initializeSpeechServices();

    }, []);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };
    const isIphone = () => {
        return /iPhone/.test(navigator.userAgent);
    };
    const streamTextGeneration = async (isWantingToRecord: boolean) => {

        const isUserOnIphone = isIphone();

        if (isUserOnIphone) {

            setIsLoading(true);
            setTalkingState('thinking');
            const audioElement: HTMLAudioElement | null = audioRef.current;
            // setInputValue('');

            audioElement?.pause();
            audioElement.currentTime = 0;

            const conversationHistory: any[] = [];
            const normal_intro = `Greet the user by saying "Hello ", and briefly introduce yourself to the user.`



            const openAIChatHistory2 = [
                ...conversationHistory,
                {
                    text: inputValue,
                    sender: "user",
                    profile: "/images/profile.png",
                    audioUrl: "",
                },
            ];

            let convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, '');


            const searchParams = new URLSearchParams();

            searchParams.set("prompt", "Hello there");
            searchParams.set("responseId", "3412321");
            searchParams.set("history", JSON.stringify(convertedMessages));
            searchParams.set("selectedVoice", selectedAudio);
            searchParams.set("introMsg", "false");
            searchParams.set("chatId", "31232");
            searchParams.set("isRAG", "0");
            setAudioSrc(`https://7fripsgtim8mne-6000.proxy.runpod.net/say-prompt?${searchParams.toString()}`);
            setAudioSrc(`https://7fripsgtim8mne-6000.proxy.runpod.net/say-prompt?${searchParams.toString()}`);

            setInputValue('');
            audioElement.load();


            audioRef.current.onplay = () => {
                console.log("Playing...");
                setIsLoading(false);
                setTalkingState('talking');
                // setTalkingState("talking");
            };

            const playAudio = () => {
                audioElement.play();

            };

            const onEnd = () => {
                if (onEndExecuted.current) return; // Check if the onEnd has been executed
                onEndExecuted.current = true; // Set the flag to true
                console.log('ended2');
                setTalkingState('idle');

                if (isWantingToRecord) {
                    // startSpeechToText();
                    try {
                        console.log("Voice to voice activated");
                        const dictateButton = document.querySelector('.dictate-button-home') as HTMLElement;
                        if (dictateButton) {
                            dictateButton.click();
                        }
                        setTalkingState("listening");
                    }
                    catch (error) {
                        console.error("Error in starting voice:", error);
                    }
                }

            };

            audioElement.addEventListener("loadeddata", playAudio);
            audioElement.addEventListener("ended", onEnd);
            onEndExecuted.current = false;

            return () => {
                audioElement.removeEventListener("loadeddata", playAudio);
                audioElement.addEventListener("ended", onEnd);


            };
        } else {
            if (isWantingToRecord) {
                if (!isUserOnIphone) {
                    try {
                        console.log("Voice to voice activated");
                        const dictateButton = document.querySelector('.dictate-button-home') as HTMLElement;
                        if (dictateButton) {
                            dictateButton.click();
                        }
                        setTalkingState("listening");
                    } catch (error) {
                        console.error("Error in starting voice:", error);
                    }
                } else {
                    setIsListening(false);
                }

            } else {
                setIsListening(false);
            }

            setIsLoading(true);
            setTalkingState('thinking');
            // const audioElement = audioRef.current;
            // // setInputValue('');

            // audioElement.pause();
            // audioElement.currentTime = 0;

            const conversationHistory: any[] = [];
            const normal_intro = `Greet the user by saying "Hello ", and briefly introduce yourself to the user.`



            const openAIChatHistory2 = [
                ...conversationHistory,
                {
                    text: inputValue,
                    sender: "user",
                    profile: "/images/profile.png",
                    audioUrl: "",
                },
            ];

            let convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, '');

            setInputValue('');

            // toast.error("user not on iphone");
            const searchParams = new URLSearchParams();
            searchParams.set("prompt", "Hello there");
            searchParams.set("responseId", "3412321");
            searchParams.set("history", JSON.stringify(convertedMessages));
            searchParams.set("selectedVoice", selectedAudio);
            searchParams.set("introMsg", "false");
            searchParams.set("chatId", "31232");
            searchParams.set("isRAG", "0");
            searchParams.set("imageVision", "");
            console.log(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

            // var url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
            var url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
            // Create a MediaSource
            const mediaSource = new MediaSource();
            const audioElement = audioRef.current;

            // Set the audio source
            audioElement.src = URL.createObjectURL(mediaSource);
            console.log('MediaSource created and URL set');

            // function onAudioEnded() {
            //   if (isWantingToRecord) {
            //     try {
            //       console.log("Voice to voice activated");
            //       const dictateButton = document.querySelector('.dictate-button-home');
            //       if (dictateButton) {
            //         dictateButton.click();
            //       } 
            //       setTalkingState("listening");
            //     } catch (error) {
            //       console.error("Error in starting voice:", error);
            //     }
            //   }
            // }




            mediaSource.addEventListener('sourceopen', async () => {
                console.log('MediaSource opened');
                const mimeCodec = 'audio/mpeg';
                if (MediaSource.isTypeSupported(mimeCodec)) {
                    console.log(`${mimeCodec} is supported`);
                    const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

                    sourceBuffer.addEventListener('updatestart', () => {
                        console.log('SourceBuffer: updatestart');
                    });

                    sourceBuffer.addEventListener('updateend', () => {
                        console.log('SourceBuffer: updateend');
                    });

                    sourceBuffer.addEventListener('error', (e) => {
                        console.error('SourceBuffer error:', e);
                    });

                    // Fetch and stream the audio data
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    console.log('Fetch response received');

                    const reader = response.body.getReader();

                    const processChunk = async ({ done, value }: any) => {
                        if (done) {
                            console.log('Stream complete');
                            mediaSource.endOfStream();
                            return;
                        }

                        // Wait for SourceBuffer to be ready
                        await new Promise<void>((resolve) => {
                            if (!sourceBuffer.updating) {
                                resolve();
                                return;
                            }
                            const onUpdateEnd = () => {
                                sourceBuffer.removeEventListener('updateend', onUpdateEnd);
                                resolve();
                            };
                            sourceBuffer.addEventListener('updateend', onUpdateEnd);
                        });

                        console.log('Appending chunk to SourceBuffer');
                        sourceBuffer.appendBuffer(value);
                        setTalkingState('talking');
                        setIsLoading(false);

                        // Read the next chunk
                        reader.read().then(processChunk);
                    };

                    // Start reading the first chunk
                    reader.read().then(processChunk);
                } else {
                    console.error(`MIME type ${mimeCodec} is not supported`);
                }
            });



            // Play the audio within the user interaction context
            audioElement.play().then(() => {
                console.log('Audio is playing');
            }).catch(error => {
                console.error('Playback error:', error);
            });

        }

        // // audioElement.addEventListener('onplay', onPlay);
        // audioElement.addEventListener("error", onError);

    }
    const audiomain = () => {
        // startListening();
        // startListeningMain();
        if (isListening) {
            setIsListening(false);
        } else {
            audioRef.current.pause();
            const audio = new Audio(tone);
            audio.volume = 0.03;
            audio.play();
            console.log(audio, "audio");
            console.log("audio effect playing");
        }
    }
    const handleKeyDown = (event: any) => {
        if (!toastShown) {
            toast.success("Press enter when finished writing text!", {
                position: 'bottom-center',
                duration: 5000, // Toast stays for 5 seconds
            });
            setToastShown(true);
        }
        if (event.key === 'Enter') {
            console.log('Enter key pressed');
            // Call your function here
            streamTextGeneration(false);
            audioRef.current.pause();
        }
    };
    const handleChange = (
        selectedOption: CustomOptionType | null,
        actionMeta: ActionMeta<CustomOptionType>
    ) => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setSelectedAudio(selectedOption['audio_url']);
        setSelectedInputPrompt(selectedOption['description']);
        setSelectedId(selectedOption['id']);
        setSelectedName(selectedOption['name']);
        setSelectedOption(selectedOption);
    };
    const handleDictate = ({ result }: any) => {
        if (isListening == true) {
            try {
                // const audio = new Audio(tone);
                // audio.volume = 0.03;
                // audio.play();
                // console.log(audio, "audio");
                console.log("audio effect playing");
                setInputValue(result.transcript);
                setIsListening(false);
                streamTextGeneration(true);
            } catch (error) {
                console.error("Error in handleDictate:", error);
            }
        }

    };
    const handleProgress = ({ results }: any) => {
        try {
            console.log("handleProgress");
            setIsListening(true);
            console.log(results);
            const interimTranscript = results.map((result: any) => result.transcript).join(' ');
            setInputValue(interimTranscript);
        } catch (error) {
            console.error("Error in handleProgress:", error);
        }
    };
    if (!speechServices) {
        return <div>Loading...</div>;
    }
    const { SpeechGrammarList, SpeechRecognition } = speechServices;
    const options: CustomOptionType[] = data;

    return (
        <div className='homeSearchMainWithOption'>
            <div className='homeSearchMain'>
                <Select
                    className="reactSelect"
                    options={options}
                    onChange={handleChange}
                    value={selectedOption}
                    isSearchable={false}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    formatOptionLabel={(option) => (
                        <div className="dropdown-img-home">
                            <i>
                                <Image src={option?.img} />
                                {talkingState === 'talking' ? <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> : ''}
                            </i>
                            <small>{option?.name}</small>
                        </div>
                    )}
                />
                <input type="text" placeholder='Ask and Generate Anything' value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} />
                <span className='Chat-msg-recorder' onClick={audiomain}>
                    <DictateButton
                        onDictate={handleDictate}
                        speechGrammarList={SpeechGrammarList}
                        speechRecognition={SpeechRecognition}
                        onProgress={handleProgress}
                        className="dictate-button-home"
                    >
                        <Icon icon="ic:baseline-mic" style={{ color: isListening ? "#fff" : "#fff7" }} />
                    </DictateButton>
                </span>
                <audio className="w-full" ref={audioRef} src={audioSrc} controls autoPlay style={{ 'display': 'none' }} />
            </div>
            <HomeSearchOption audioRef={audioRef} selectedName={selectedName}/>
        </div>
    )
}
export default HomeSearch;
