import { Col, Container, Image, Row } from 'react-bootstrap';
import './OBIToken.css'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const OBIToken = () => {
    return (
        <section className='OBIToken-Main py-100' id='OBIToken'>
            <Container>
                <Row className='align-items-center justify-content-center'>
                    <Col lg={6}>
                        <div  className='OBIToken-img'>
                            <Image src='/assets/OBI.png' />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='OBIToken-Info'>
                            <h2 data-aos="fade-up" className='main-title'>The OBI Token</h2>
                            <p data-aos="fade-up">The OBI Token is an in-platform currency that powers the economy, the compute, and the transactional layer of eventually Billions of productive AI agents and companions.</p>
                            <Link to="https://wiki.orbofi.com/learn/orbofi-token-usdobi/tokenomics" className='btn-blue btn-blue-icon '>Learn more <i><Icon icon="mdi:sparkles" /></i></Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default OBIToken;
