import { Col, Container, Image, Row } from 'react-bootstrap';
import './FeatureBox.css'
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const FeatureBox = () => {

    return (
        <section className='FeatureBox-Main py-100' id='Features'>
            <Container>
                <Row>
                    <Col lg={6} md={6}>
                        <div className='FeatureBox-box FeatureBox-box-01'>
                            <span><Icon icon="mdi:account-voice" /></span>
                            <h6>Voice AI companions, for any purpose and idea</h6>
                            <p>create and customize the personality, voice, skills of your AI for any purpose you need. all in under 1min, and for free</p>
                            <Link to="#">Learn more</Link>
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className='FeatureBox-box FeatureBox-box-02'>
                            <span><Icon icon="hugeicons:virtual-reality-vr-01" /></span>
                            <h6>More than just an AI clone, a virtual extension</h6>
                            <p>Turn yourself, your friends, anyone, into powerful and smart AI companions with your own voices</p>
                            <Link to="#">Learn more</Link>
                        </div>
                    </Col>
                    <Col lg={8} md={6}>
                        <div className='FeatureBox-box FeatureBox-box-03'>
                            <span><Icon icon="solar:documents-broken" /></span>
                            <h6>Turn documents and links into private or public voice AI agents, with a personality</h6>
                            <p>Privately, and Easily create voice AI agents trained on any documents, link, data, you can upload, and chat with it</p>
                            <Link to="#">Learn more</Link>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className='FeatureBox-box FeatureBox-box-04'>
                            <span><Icon icon="mdi:account-voice" /></span>
                            <h6>Turn your app or website into a personalized voice AI assistant</h6>
                            <p>train and create a personified voice AI agent on your own brand, website, database, products, that your team or your customers can interact with</p>
                            <Link to="#">Learn more</Link>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className='FeatureBox-box FeatureBox-box-05'>
                            <span><Icon icon="hugeicons:ai-network" /></span>
                            <h6>Turn finetuned AI models into Voice AI assistants, with a personality</h6>
                            <p>Upload finetuned AI models, and turn them into voice AI chatbots, that generate what they're supposed to generate(coming soon)</p>
                            <Link to="#">Learn more</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default FeatureBox;
