import { useState, useEffect, useRef } from 'react';
import parse from "html-react-parser";
import './Typewriter.css'

const Typewriter = ({ text, speed, onComplete, onChange }:any) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentText, setCurrentText] = useState('');
  const textIndex = useRef(0);
  const intervalId = useRef(null);

  useEffect(() => {
    const type = () => {
      if (textIndex.current < currentText.length) {
        setDisplayedText((prevDisplayedText) => {
          const nextChar = currentText.charAt(textIndex.current);
          textIndex.current += 1;

          if (nextChar === '[' && onComplete) {
            clearInterval(intervalId.current);
            onComplete();
          }
          onChange(prevDisplayedText + nextChar)
          return prevDisplayedText + nextChar;
        });
        
        // console.log('TEXT', text);
        // console.log('Displayed text', displayedText);
        // console.log('Text Index', textIndex.current);
        // console.log('Text Length', text.length);
      } else {
        // clearInterval(intervalId.current);
        // if (onComplete && textIndex.current >= text.length) {
        //     onComplete();
        //   }
        // toast.error("TypeWriter is done?")
      }
    };

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(type, speed);

    return () => clearInterval(intervalId.current);
  }, [currentText, speed, onComplete]);

  useEffect(() => {
    if (text.length > displayedText.length) {
      setCurrentText(text);
    }
  }, [text]);

  return <div className="typewriter">{parse(displayedText)}</div>;
};

export default Typewriter;
