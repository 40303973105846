import React, { useState, useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useInView } from 'react-intersection-observer';
import './WhatIsOrbofi.css';

const LazyLoadVideo = ({ url }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load only once when it comes into view
    threshold: 0.25, // Adjust threshold as needed
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [inView]);

  return (
    <div ref={ref} className='WhatIsOrbofi-Img' data-aos="fade-up">
      {inView ? (
        <ReactPlayer
          width='100%'
          height='100%'
          playing={isPlaying}
          muted={true}
          loop={true}
          controls={false}
          url={url}
        />
      ) : (
        <div style={{ width: '100%', height: '100%'}} className='WhatIsOrbofi-Img' data-aos="fade-up">
          <Image src='/assets/about-orbofi.jpg' />
        </div>
      )}
    </div>
  );
};

const WhatIsOrbofi = () => {
  return (
    <section className='WhatIsOrbofi-Main py-100 pt-0'>
      <Container>
        <Row className='align-items-center'>
          <Col lg={6} md={6}>
            <LazyLoadVideo url='/assets/about-orbofi.mp4' />
          </Col>
          <Col lg={6} md={6}>
            <div className='WhatIsOrbofi-info'>
              <h2 data-aos="fade-up" className='main-title'>What is Orbofi ?</h2>
              <p data-aos="fade-up" className=''>Orbofi is an AI engine that empowers consumers and developers to create and share powerful, hyper-personalized, and multimodal voice AI agents, that can generate and act. </p>
              <p data-aos="fade-up">Refining the human-software interaction, by putting personality and voice into any kind of user-created application</p>
              <Link data-aos="fade-up" className='btn-blue btn-blue-icon mt-3' to="https://wiki.orbofi.com/">
                Learn more <i><Icon icon="mdi:sparkles" /></i>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhatIsOrbofi;
