import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './CreateAiAudio.css'
import CanvasAnimation from './CanvasAnimation';

const CreateAiAudio = () => {
    return (
        <section className='CreateAiAudio-Main py-100'>
            <CanvasAnimation />
            <Container>
                <div className='CreateAiAudio-box'>
                    <span data-aos="fade-up"><Image src='/assets/logo-icon.svg' /></span>
                    <h2 data-aos="fade-up" className='main-title'>Create with the highest quality AI Audio</h2>
                    <Link data-aos="fade-up" to="https://www.orbofi.com/" className='btn-blue btn-blue-icon '>Get Started Free <i><Icon icon="mdi:sparkles" /></i></Link>
                </div>
            </Container>
        </section>
    )
};

export default CreateAiAudio;
