import { Container, Image } from 'react-bootstrap';
import './CreateAIBanne.css'

const CreateAIBanne = () => {

    return (
        <section className='CreateAIBanne-Main py-100'>
            <Container>
                <h2 data-aos="fade-up" className='main-title text-center '>Create powerful ​and personalized AI ​companions, with ​real voice, and ​person​ality.</h2>
                <p data-aos="fade-up" className='main-title-text '>Create in a few clicks Your Jarvis-like AI companion that can ​take the shape, voice, and form of anyone and anything you ​wa​nt, powered by the most performant AI models.</p>
                <span data-aos="fade-up" className=''><Image src='/assets/ai-banner.png' /></span>
            </Container>
        </section>
    )
};

export default CreateAIBanne;
