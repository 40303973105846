import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './HowItWorks.css'

const HowItWorks = () => {
    return (
        <section className='HowItWorks-Main py-100 pb-0'>
            <Container>
                <h2 data-aos="fade-up" className='main-title text-center'>How it works</h2>
                <Row className='align-items-center'>
                    <Col lg={4} md={4} sm={4}>
                        <div data-aos="fade-up" className='HowItWorks-main'>
                            <div className='HowItWorks-img'>
                                <Image src='/assets/HowItWorks-img-01.png' />
                            </div>
                            <h6>Create the brain, skill and personality</h6>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div data-aos="fade-up" className='HowItWorks-main'>
                            <div className='HowItWorks-img'>
                                <Image src='/assets/HowItWorks-img-02.png' />
                            </div>
                            <h6>Clone or choose a voice</h6>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div data-aos="fade-up" className='HowItWorks-main'>
                            <div className='HowItWorks-img'>
                                <Image src='/assets/HowItWorks-img-03.png' />
                            </div>
                            <h6>Voila! Your AI companion is a live</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default HowItWorks;
