import React, { useState, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import './BannerVideo.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const LazyLoadVideo = ({ url }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load only once when it comes into view
    threshold: 0.25, // Adjust threshold as needed
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [inView]);

  return (
    <div ref={ref} className='BannerVideo-video' data-aos="fade-up">
      {inView ? (
        <ReactPlayer
          width='100%'
          height='100%'
          playing={isPlaying}
          muted={true}
          loop={true}
          controls={false}
          url={url}
        />
      ) : (
        <div style={{ width: '100%', height: '100%'}} className='BannerVideo-video' data-aos="fade-up">
          <Image src='/assets/banner-video-02.jpg' />
        </div>
      )}
    </div>
  );
};

const BannerVideo = () => {
  return (
    <section className='BannerVideo-Main py-100'>
      <Container>
        <h2 className='main-title'>Powerful AI agents, personified and voiced by anyone, capable of anything</h2>
        <p>An engine that turns any person, data, file, bits and atoms, into powerful voice AI companion powered by any kind of AI model</p>
        <Link to="https://www.orbofi.com/" className='btn-blue btn-blue-icon '>Create your AI <i><Icon icon="mdi:sparkles" /></i></Link>
        <LazyLoadVideo url='/assets/banner-video-02.mp4' />
      </Container>
    </section>
  );
};

export default BannerVideo;
