import { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './Pages/HomePage/HomePage'
import Header from './Components/Header/Header';
import Loader from './Components/Loader/Loader';
import Chatpopup from './Components/Chatpopup/Chatpopup';
import { Toaster } from 'react-hot-toast';
import Footer from './Components/Footer/Footer';
import './App.css'

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // close the loading animation upon opening the website
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Routes>
            <Route index element={<HomePage />} />
          </Routes>
          <Footer />
          <Toaster position="bottom-right" />
          <Chatpopup />/
        </>
      )}
    </>
  )
}

export default App
