import AIClones from "../../Components/AIClones/AIClones";
import AIClonesbots from "../../Components/AIClonesbots/AIClonesbots";
import AppUseCase from "../../Components/AppUseCase/AppUseCase";
import ArtificialIntelligence from "../../Components/ArtificialIntelligence/ArtificialIntelligence";
import BannerVideo from "../../Components/BannerVideo/BannerVideo";
import CompanionsVoice from "../../Components/CompanionsVoice/CompanionsVoice";
import CreateAiAudio from "../../Components/CreateAiAudio/CreateAiAudio";
import CreateAIBanne from "../../Components/CreateAIBanne/CreateAIBanne";
import CreateAICompanionPage from "../../Components/CreateAICompanionPage/CreateAICompanionPage";
import FeatureBox from "../../Components/FeatureBox/FeatureBox";
import HomeBanner from "../../Components/HomeBanner/HomeBanner";
import HowItWorks from "../../Components/HowItWorks/HowItWorks";
import JoinOurCommunity from "../../Components/JoinOurCommunity/JoinOurCommunity";
import LatestUpdates from "../../Components/LatestUpdates/LatestUpdates";
import OBIToken from "../../Components/OBIToken/OBIToken";
import TeamSupports from "../../Components/TeamSupports/TeamSupports";
import WhatIsOrbofi from "../../Components/WhatIsOrbofi/WhatIsOrbofi";

const HomePage = () => {
    return <>
        <HomeBanner />
        <TeamSupports />
        <WhatIsOrbofi />
        <BannerVideo />
        <CreateAIBanne />
        <CompanionsVoice />
        {/* <CreateAICompanionPage /> */}
        <HowItWorks />
        <section className="py-100 pt-0"></section>
        <AppUseCase />
        <FeatureBox />
        <ArtificialIntelligence />
        <AIClonesbots />
        <OBIToken />
        <CreateAiAudio />
        <JoinOurCommunity />
        <section className="py-100 pt-0"></section>
        {/* <LatestUpdates /> */}
        {/* <AIClones /> */}
    </>;
  };
  
  export default HomePage;
  