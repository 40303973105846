import Container from 'react-bootstrap/Container';
import './Footer.css'
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

function Footer() {
    return (
        <footer>
            <Container>
                <div className='bg-blue'>
                    <Row>
                        <Col lg={5} md={5}>
                            <div className='footer-info'>
                                <Link data-aos="fade-up" to="#" className='footer-logo'><Image src='/assets/logo-white.svg' /></Link>
                                <p data-aos="fade-up">Use any Smart AI clone to get answers for anything, generate images, code, or just to chit-chat. Smart AI clones are personalities with AI superpowers</p>
                                <div data-aos="fade-up" className='news-letter'>
                                    <input type="text" placeholder='Your Email Address' />
                                    <Link to="#" className='btn-white'>Send</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={2}>
                            <div className='footer-item'>
                                <h6 data-aos="fade-up">About us</h6>
                                <ul>
                                    <li data-aos="fade-up"><Link target='_blank' to="https://wiki.orbofi.com/learn">Our Story </Link></li>
                                    <li data-aos="fade-up"><Link target='_blank' to="https://orbofi.medium.com/">Blog </Link></li>
                                    <li data-aos="fade-up"><Link target='_blank' to="https://forms.gle/5y7idmy3UQgnFtuB7">Contact Us </Link></li>
                                    <li data-aos="fade-up"><Link target='_blank' to="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/privacy-policy">Privacy Policy </Link></li>
                                    <li data-aos="fade-up"><Link target='_blank' to="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/terms-of-services">Terms & Conditions </Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4} md={5}>
                            <div className='footer-item'>
                                <h6 data-aos="fade-up">Join community</h6>
                                <ul className='footer-social'>
                                <li data-aos="fade-up"><Link target='_blank' to="https://x.com/Orbofi?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><Icon icon="ri:twitter-x-fill" /></Link></li>
                                <li data-aos="fade-up"><Link target='_blank' to="https://t.me/orbofi"><Icon icon="mingcute:telegram-fill" /></Link></li>
                                <li data-aos="fade-up"><Link target='_blank' to="https://discord.com/invite/orbofi"><Icon icon="mingcute:discord-fill" /></Link></li>
                                <li data-aos="fade-up"><Link target='_blank' to="https://www.linkedin.com/company/orbofi"><Icon icon="ri:linkedin-fill" /></Link></li>
                                <li data-aos="fade-up"><Link target='_blank' to="https://www.reddit.com/r/Orbofi/"><Icon icon="ri:instagram-fill" /></Link></li>
                                <li data-aos="fade-up"><Link target='_blank' to="https://www.instagram.com/orbofi/"><Icon icon="ri:reddit-fill" /></Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <p data-aos="fade-up" className='copyright'>© 2024 Orbofi Inc. All Rights Reserved.</p>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;